import { DepartmentRepo } from "@/repos";
import { pageSize } from '@/configs/api.config'

const perPage = pageSize || 10;

class DepartmentService {
    async list(params) {
        params = params || {};
        params['page_size'] = params['page_size'] || perPage;

        let { data } = await DepartmentRepo.list(params);
        data.page = params.page || 1;
        data.totalPage = Math.ceil(data.count / params['page_size']);

        return data;
    }

    async get(id) {
        let { data } = await DepartmentRepo.get(id);
        return data;
    }

    async create(user) {
        let { data } = await DepartmentRepo.create(user);
        return data;
    }
    
    async update(id, user) {
        let { data } = await DepartmentRepo.update(id, user);
        return data;
    }

    async delete(id) {
        let { data } = await DepartmentRepo.delete(id);
        return data;
    }

    async setStaffs(id, staffs) {
        let { data } = await DepartmentRepo.setStaffs(id, staffs);
        return data;
    }
}

export default new DepartmentService();