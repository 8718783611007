<template>
  <div class="bg-bg_color_2-300">
    <sidebar />
    <div class="relative md:ml-64 pt-4">
      <router-view />
    </div>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar/Sidebar.vue";
export default {
  name: "admin-layout",
  components: {
    Sidebar
  },
};
</script>
