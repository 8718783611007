<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded
      bg-bg_color_white
    "
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center justify-between">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <div class="flex items-center gap-6 justify-between md:justify-start">
            <h3 class="font-semibold text-lg text-text_color_1-700">
              {{ $t("app.table-title") }}
            </h3>
            <span class="ml-4 font-thin text-xs">
              <a
                class="px-4 py-4"
                @click="prevPage"
                :class="page > 1 ? 'cursor-pointer' : 'text-text_color_1-400'"
                ><i class="fas fa-chevron-left"
              /></a>
              {{ page }} / {{ totalPage }}
              <a
                class="px-4 py-4"
                :class="
                  page < totalPage ? 'cursor-pointer' : 'text-text_color_1-400'
                "
                @click="nextPage"
                ><i class="fas fa-chevron-right" /></a
            ></span>
          </div>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
              "
            >
              {{ $t("line.table.message") }}
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
              "
            >
              {{ $t("line.table.sent_at") }}
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
              "
            >
              {{ $t("line.table.status") }}
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
                cursor-pointer
              "
              @click="sorting('created_at')"
            >
              {{ $t("line.table.created_at") }}
              <i class="fas" :class="getSortClass('created_at')"></i>
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
              "
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(rec, i) in records" :key="i">
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
                text-left
                items-center
              "
            >
              <span class="ml-3 font-bold">
                {{ `${rec.message.substring(0, 50) || "-"}` }}
                {{ rec.message.length > 50 ? "..." : "" }}
              </span>
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              {{
                `${
                  formatDate(rec.send_at, "DD MMMM YYYY HH:mm") ||
                  $t("line.table.send_at_now")
                }`
              }}
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              <span class="ml-3 font-bold">
                {{ resolveStatus(rec.is_sent, rec.is_success) }}
              </span>
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              {{ formatDate(rec.created_at, "DD MMMM YYYY HH:mm") }}
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
                text-right
              "
            >
              <button
                v-if="!rec.is_sent"
                class="
                  text-text_color_white
                  bg-btn_red_color_active-500
                  active:bg-btn_red_color_active-600
                  font-bold
                  uppercase
                  text-xs
                  px-4
                  py-2
                  rounded
                  shadow
                  hover:shadow-md
                  outline-none
                  focus:outline-none
                  ease-linear
                  transition-all
                  duration-150
                "
                type="button"
                @click="$emit('delete', rec.id)"
              >
                <i class="fas fa-trash"></i> {{ $t("app.button.cancel") }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      defaultSortStatus: {
        created_at: 0,
      },
      sortStatus: {
        created_at: 0,
      },
    };
  },
  emits: ["onSortChange", "onPageChange "],
  props: {
    records: {
      default: [],
    },
    page: {
      default: 1,
    },
    totalPage: {
      default: 1,
    },
  },
  methods: {
    formatDate(val, format) {
      if (!val) return "-";
      return this.$moment(val).locale("th").format(format);
    },
    getSortClass(field) {
      return ["fa-sort", "fa-sort-up", "fa-sort-down"][
        this.sortStatus[field] || 0
      ];
    },
    resolveStatus(isSent, isSuccess) {
      if (!isSent) return this.$t("line.table.status_pending");
      if (isSuccess) return this.$t("line.table.status_success");
      return this.$t("line.table.status_fail");
    },
    sorting(field) {
      const nextState =
        this.sortStatus[field] >= 2 ? 0 : this.sortStatus[field] + 1;
      this.sortStatus = { ...this.defaultSortStatus };
      this.sortStatus[field] = nextState;
      const orderingQuery = [null, `${field}`, `-${field}`][nextState];
      this.$emit("onSortChange", orderingQuery);
    },
    prevPage() {
      if (this.page > 1) this.$emit("onPageChange", this.page - 1);
    },
    nextPage() {
      if (this.page < this.totalPage) this.$emit("onPageChange", this.page + 1);
    },
  },
};
</script>
