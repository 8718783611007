import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import { createI18n } from 'vue-i18n'
import moment from 'moment-timezone'
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";
import th from "@/assets/i18n/th.json";
import en from "@/assets/i18n/en.json";
import App from "@/App.vue";

import Multiselect from '@vueform/multiselect'

import Admin from "@/layouts/Admin.vue";

import AccPurchase from "@/views/admin/AccPurchase.vue";
import Card from "@/views/admin/Card.vue";
import Coupon from "@/views/admin/Coupon.vue";
import CouponUsage from "@/views/admin/CouponUsage.vue";
import Dashboard from "@/views/admin/Dashboard.vue";
import Event from "@/views/admin/Event.vue";
import Department from "@/views/admin/Department.vue";
import Line from "@/views/admin/Line.vue";
import LineHistory from "@/views/admin/LineHistory.vue";
import Report from "@/views/admin/Report.vue";
import Segment from "@/views/admin/Segment.vue";
import Staff from "@/views/admin/Staff.vue";
import User from "@/views/admin/User.vue";
import Index from "@/views/Index.vue";

import Loading from '@/components/Common/Loading.vue'

const routes = [
  {
    path: "/admin",
    redirect: "/admin/users",
    component: Admin,
    children: [
      {
        path: "/admin/dashboard",
        component: Dashboard
      },
      {
        path: "/admin/users",
        component: User,
      },
      {
        path: "/admin/events",
        component: Event,
      },
      {
        path: "/admin/Segments",
        component: Segment,
      },
      {
        path: "/admin/cards",
        component: Card,
      },
      {
        path: "/admin/acc-purchase",
        component: AccPurchase,
      },
      {
        path: "/admin/coupons",
        component: Coupon,
      },
      {
        path: "/admin/coupon-usages",
        component: CouponUsage,
      },
      {
        path: "/admin/departments",
        component: Department,
      },
      {
        path: "/admin/line",
        component: Line,
      },
      {
        path: "/admin/line-history",
        component: LineHistory,
      },
      {
        path: "/admin/staffs",
        component: Staff,
      },
      {
        path: "/admin/reports",
        component: Report,
      },
    ],
  },
  {
    path: "/",
    component: Index,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const isAuthenticated = () => {
  return !!localStorage.getItem('access_token');
}

router.beforeEach((to, from, next) => {
  if (to.path !== '/' && !isAuthenticated()) next('/')
  else next()
})

const i18n = createI18n({
  locale: 'th',
  messages: {
    th: th,
    en: en
  },
  fallbackLocale: 'th',
  detectBrowserLanguage: {
    useCookie: true,
    cookieKey: "locale",
    onlyOnRoot: true,
    alwaysRedirect: true
  },
})

moment.tz.setDefault('Asia/Bangkok')

let app = createApp(App)
app.config.globalProperties.$moment=moment;
app.use(router)
  .use(i18n)
  .component('Multiselect', Multiselect)
  .component('loader', Loading)
  .mount("#app");