import BaseRepo from '@/repos/BaseRepo';

const API_PATH = '/bo/coupon_usage/';

class CouponUsageRepo {
    list(params) {
        return BaseRepo.get(API_PATH, { params });
    }
}

export default new CouponUsageRepo();