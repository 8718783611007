<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-bg_color_1-100
      border-0
    "
  >
    <div class="rounded-t bg-bg_color_white mb-0 px-6 py-3">
      <div class="text-center flex justify-between">
        <h6 class="text-text_color_1-700 text-xl font-bold">
          {{ $t("app.search-segment") }}
        </h6>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10">
      <div class="flex flex-wrap">
        <div class="w-full lg:w-4/12 px-4">
          <div class="flex w-full items-center">
            <input
              v-model="type"
              type="radio"
              value="single"
              class="
                border-0
                px-4
                py-4
                mr-4
                placeholder-placeholder_color_1-300
                text-text_color_1-600
                bg-bg_color_white
                rounded
                text-sm
                shadow
                focus:outline-none
                focus:ring
                ease-linear
                transition-all
                duration-150
              "
            />
            <label
              class="block uppercase text-text_color_1-600 text-xs font-bold"
              htmlFor="grid-password"
            >
              {{ $t("line.search.single") }}
            </label>
          </div>
        </div>

        <div class="w-full lg:w-4/12 px-4">
          <div class="flex w-full items-center">
            <input
              v-model="type"
              type="radio"
              value="multicast"
              class="
                border-0
                px-4
                py-4
                mr-4
                placeholder-placeholder_color_1-300
                text-text_color_1-600
                bg-bg_color_white
                rounded
                text-sm
                shadow
                focus:outline-none
                focus:ring
                ease-linear
                transition-all
                duration-150
              "
            />
            <label
              class="block uppercase text-text_color_1-600 text-xs font-bold"
              htmlFor="grid-password"
            >
              {{ $t("line.search.multicast") }}
            </label>
          </div>
        </div>

        <div class="w-full lg:w-4/12 px-4">
          <div class="flex w-full items-center">
            <input
              v-model="type"
              type="radio"
              value="broadcast"
              class="
                border-0
                px-4
                py-4
                mr-4
                placeholder-placeholder_color_1-300
                text-text_color_1-600
                bg-bg_color_white
                rounded
                text-sm
                shadow
                focus:outline-none
                focus:ring
                ease-linear
                transition-all
                duration-150
              "
            />
            <label
              class="block uppercase text-text_color_1-600 text-xs font-bold"
              htmlFor="grid-password"
            >
              {{ $t("line.search.broadcast") }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: "single",
    };
  },
  emits: ["onApply"],
  watch: {
    type(val) {
      this.$emit("onApply", val);
    },
  },
};
</script>
