<template>
  <nav
    class="
      right-nav
      fixed
      top-0
      bottom-0
      overflow-y-auto overflow-hidden
      shadow-xl
      bg-bg_color_white
      w-64
      z-60
      py-4
      px-6
      h-full
    "
    :class="model ? 'active' : 'inactive'"
  >
    <div class="flex justify-start">
      <a
        class="
          text-text_color_1-400
          block
          cursor-pointer
          px-4
          py-4
          border
          border-border_color_1-400
          rounded-lg
        "
        @click="$emit('close')"
      >
        <i class="fas fa-angle-double-right"></i>
      </a>
    </div>

    <div v-if="model" class="flex flex-col items-center">
      <!-- Head -->
      <div class="flex flex-wrap justify-center">
        <div class="w-full px-4 flex justify-center">
          <div class="relative">
            <img
              alt="avatar"
              :src="model.line_picture_url || defaultAvatar"
              class="
                shadow-xl
                rounded-full
                h-auto
                align-middle
                border-none
                max-w-150-px
              "
            />
          </div>
        </div>

        <!-- Info -->
        <div class="w-full px-4 md:px-8">
          <div class="flex justify-center py-4 pt-8">
            <h3
              class="
                text-xl
                font-semibold
                leading-normal
                text-text_color_1-700
                mb-2
              "
            >
              {{ model.first_name || "-" }} {{ model.last_name || "-" }}
            </h3>
          </div>

          <div class="mb-2 text-text_color_1-600">
            <span class="min-w-80-px inline-block text-right pr-2">
              <span class="font-bold text-text_color_1-400">LINE</span>
            </span>
            {{ model.line_display_name }}
          </div>

          <div class="mb-2 text-text_color_1-600">
            <span class="min-w-80-px inline-block text-right pr-2">
              <i
                class="fas fa-phone-alt mr-2 text-lg text-text_color_1-400"
              ></i>
            </span>
            {{ model.mobile }}
          </div>

          <div class="mb-2 text-text_color_1-600">
            <span class="min-w-80-px inline-block text-right pr-2">
              <i
                class="fas fa-venus-mars mr-2 text-lg text-text_color_1-400"
              ></i>
            </span>
            {{ model.gender }}
          </div>

          <div class="mb-2 text-text_color_1-600">
            <span class="min-w-80-px inline-block text-right pr-2">
              <i
                class="fas fa-birthday-cake mr-2 text-lg text-text_color_1-400"
              ></i>
            </span>
            {{ formatDate(model.birth_date, "DD MMMM YYYY") }}
          </div>

          <div class="mb-2 text-text_color_1-600">
            <span class="min-w-80-px inline-block text-right pr-2">
              <i
                class="fas fa-map-marker-alt mr-2 text-lg text-text_color_1-400"
              ></i>
            </span>
            {{ model.address_1 }} {{ model.sub_district }} {{ model.district }}
            {{ model.province }} {{ model.post_code }}
          </div>
          <div class="mb-2 text-text_color_1-600">
            <span class="min-w-80-px inline-block text-right pr-2">
              <span class="font-bold text-text_color_1-400">{{
                $t("users.table.is_active")
              }}</span>
            </span>
            {{
              model.user_package && model.user_package.is_activate
                ? $t("users.table.activate_true")
                : $t("users.table.activate_false")
            }}
          </div>

          <div class="mb-2 text-text_color_1-600">
            <span class="min-w-80-px inline-block text-right pr-2">
              <span class="font-bold text-text_color_1-400">{{
                $t("users.table.is_accept_market")
              }}</span>
            </span>
            {{
              model.is_accept_market
                ? $t("users.table.is_accept_market_true")
                : $t("users.table.is_accept_market_false")
            }}
          </div>
          <div class="mb-2 text-text_color_1-600">
            <span class="min-w-80-px inline-block text-right pr-2">
              <span class="font-bold text-text_color_1-400">{{
                $t("users.table.is_accept_policy")
              }}</span>
            </span>
            {{
              model.is_accept_policy
                ? $t("users.table.is_accept_policy_true")
                : $t("users.table.is_accept_policy_false")
            }}
          </div>

          <div class="mb-2 text-text_color_1-600">
            <span class="min-w-80-px inline-block text-right pr-2">
              <span class="font-bold text-text_color_1-400">{{
                $t("users.table.registered_date")
              }}</span>
            </span>
            {{ formatDate(model.registered_at, "DD MMMM YYYY, HH:mm") }}
          </div>

          <div class="mb-2 text-text_color_1-600">
            <span class="min-w-80-px inline-block text-right pr-2">
              <span class="font-bold text-text_color_1-400">{{
                $t("users.form.event_package")
              }}</span>
            </span>
            {{
                model.user_package && model.user_package.type == "package"
                  ? `${
                      model.user_package.event_package &&
                      model.user_package.event_package.member_card_set_code
                    } (${model.user_package.code})`
                  : `${
                      model.user_package.event_package &&
                      model.user_package.event_package.member_card_set_code
                    }`
              }}
          </div>

          <div
            v-if="model.user_package && model.user_package.current_member_card"
            class="mb-2 text-text_color_1-600"
          >
            <span class="min-w-80-px inline-block text-right pr-2">
              <span class="font-bold text-text_color_1-400">
                <span class="font-bold text-text_color_1-400">{{
                  $t("users.form.card")
                }}</span>
              </span>
            </span>
            {{
              (model.user_package &&
                model.user_package.current_member_card &&
                model.user_package.current_member_card.name) ||
              "-"
            }}
            {{
              (model.user_package &&
                model.user_package.expired_at &&
                `(${$t("users.form.card_expire")} ${formatDate(
                  model.user_package.expired_at,
                  "DD MMMM YYYY"
                )})`) ||
              "-"
            }}
          </div>
        </div>

        <!-- Child Surveys -->
        <div class="w-full px-4 md:px-8">
          <hr class="my-6 border-b-1 border-border_color_1-300" />
          <div class="mb-2 text-text_color_1-600">
            <span class="mb-2 min-w-80-px inline-block text-right pr-2">
              <span class="font-bold text-text_color_1-400">{{
                $t("users.form.questions")
              }}</span>
            </span>
            <ol class="list-decimal list-inside">
              <li v-for="(userAnswer, i) in model.user_answers" :key="i">
                <span>{{ userAnswer.answer || "-" }}</span>
              </li>
            </ol>
          </div>
        </div>

        <!-- Child Segment -->
        <div class="w-full px-4 md:px-8">
          <hr class="my-6 border-b-1 border-border_color_1-300" />
          <div class="mb-2 text-text_color_1-600">
            <span class="mb-2 min-w-80-px inline-block text-right pr-2">
              <span class="font-bold text-text_color_1-400">{{
                $t("users.form.segments")
              }}</span>
            </span>
            <ol class="list-decimal list-inside">
              <li v-for="(userSegment, i) in model.user_custom_group" :key="i">
                <span>{{ userSegment.custom_group && userSegment.custom_group.name || "-" }}</span>
              </li>
            </ol>
          </div>
        </div>

        <!-- Child Coupon -->
        <div class="w-full px-4 md:px-8">
          <hr class="my-6 border-b-1 border-border_color_1-300" />
          <div class="mb-2 text-text_color_1-600">
            <span class="mb-2 min-w-80-px inline-block text-right pr-2">
              <span class="font-bold text-text_color_1-400">{{
                $t("users.form.coupons")
              }}</span>
            </span>
            <ol class="list-decimal list-inside">
              <li v-for="(userCoupon, i) in (model.user_coupons || []).filter(c => c.is_active)" :key="i">
                <span>{{ userCoupon.coupon_data && userCoupon.coupon_data.name || "-" }}</span>
              </li>
            </ol>
          </div>
        </div>

        <!-- Action -->
        <div class="flex flex-wrap gap-6 justify-center mt-20">
          <button
            class="
              bg-btn_yellow_color_active-500
              text-text_color_white
              active:bg-btn_yellow_color_active-600
              font-bold
              uppercase
              text-xs
              px-8
              py-4
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            type="button"
            @click="$emit('edit', model)"
          >
            <i class="fas fa-edit"></i> {{ $t("app.button.edit") }}
          </button>
          <button
            class="
              bg-btn_red_color_active-500
              text-text_color_white
              active:bg-btn_red_color_active-600
              font-bold
              uppercase
              text-xs
              px-8
              py-4
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            type="button"
            @click="$emit('delete', model)"
          >
            <i class="fas fa-trash"></i> {{ $t("app.button.delete") }}
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>
); }

<script>
import defaultAvatar from "@/assets/img/default-avatar.jpg";
export default {
  data() {
    return {
      defaultAvatar,
    };
  },
  emits: ["close", "delete", "edit"],
  props: {
    model: {
      default: null,
    },
  },
  methods: {
    formatDate(val, format) {
      if (!val) return "-";
      return this.$moment(val).locale("th").format(format);
    },
  },
};
</script>

<style scoped>
.right-nav {
  left: auto;
  right: 0;
  width: 50%;
  max-width: 50%;
}

@media (max-width: 768px) {
  .right-nav {
    left: auto;
    right: 0;
    width: 100%;
    max-width: 100%;
  }
}

.active {
  transform: translatex(0);
  transition: all 0.2s ease-out;
}

.inactive {
  transform: translatex(100%);
  transition: all 0.2s ease-out;
}
</style>