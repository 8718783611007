<template>
  <nav
    class="
      right-nav
      fixed
      top-0
      bottom-0
      overflow-y-auto overflow-hidden
      shadow-xl
      bg-bg_color_1-100
      w-64
      z-65
      py-4
      px-6
      h-full
    "
    :class="active ? 'active' : 'inactive'"
  >
    <div class="flex justify-start">
      <a
        class="
          text-text_color_1-400
          block
          cursor-pointer
          px-4
          py-4
          border
          border-border_color_1-400
          rounded-lg
        "
        @click="$emit('close')"
      >
        <i class="fas fa-angle-double-right"></i>
      </a>
    </div>
    <div v-if="active" class="flex flex-col items-center">
      <form @submit.prevent="save">
        <h6 class="text-text_color_1-400 text-sm mt-3 mb-3 font-bold uppercase">
          {{ $t("users.form.label-section1") }}
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4 pt-2">
            <div class="relative w-full">
              <label
                class="
                  block
                  uppercase
                  text-text_color_1-600
                  text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("users.form.first_name") }}
              </label>
              <input
                v-model="form.first_name"
                required
                type="text"
                class="
                  border-0
                  px-3
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>

          <div class="w-full lg:w-6/12 px-4 pt-2">
            <div class="relative w-full">
              <label
                class="
                  block
                  uppercase
                  text-text_color_1-600
                  text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("users.form.last_name") }}
              </label>
              <input
                v-model="form.last_name"
                type="text"
                class="
                  border-0
                  px-3
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>

          <div class="w-full lg:w-4/12 px-4 pt-2">
            <div class="relative w-full">
              <label
                class="
                  block
                  uppercase
                  text-text_color_1-600
                  text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("users.form.mobile") }}
              </label>
              <input
                v-model="form.mobile"
                required
                type="text"
                class="
                  border-0
                  px-3
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>

          <div class="w-full lg:w-4/12 px-4 pt-2">
            <div class="relative w-full">
              <label
                class="
                  block
                  uppercase
                  text-text_color_1-600
                  text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("users.form.gender") }}
              </label>
              <select
                v-model="form.gender"
                class="
                  border-0
                  border-border_color_1-500
                  px-2
                  pr-6
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  ease-linear
                  transition-all
                  duration-150
                  ml-2
                  w-full
                "
              >
                <option value="Male">
                  {{ $t("users.form.gender_opt.male") }}
                </option>
                <option value="Female">
                  {{ $t("users.form.gender_opt.female") }}
                </option>
                <option value="Prefer not to say">
                  {{ $t("users.form.gender_opt.prefer_not_to_say") }}
                </option>
              </select>
            </div>
          </div>

          <div class="w-full lg:w-4/12 px-4 pt-2">
            <div class="relative w-full">
              <label
                class="
                  block
                  uppercase
                  text-text_color_1-600
                  text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("users.form.birth_date") }}
              </label>
              <datepicker
                v-model="form.birth_date_fmt"
                :upperLimit="today"
                class="
                  border-0
                  px-3
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>
        </div>

        <!-- Divider -->
        <hr class="mt-6 border-b-1 border-border_color_1-300" />

        <div class="flex flex-wrap">
          <div class="w-full lg:w-4/12 px-4 pt-2">
            <div class="relative w-full">
              <label
                class="
                  block
                  uppercase
                  text-text_color_1-600
                  text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("users.form.province") }}
              </label>
              <select
                v-model="seletedProvince"
                class="
                  border-0
                  border-border_color_1-500
                  px-3
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  ease-linear
                  transition-all
                  duration-150
                  w-full
                "
              >
                <option
                  v-for="(province, i) in provinces"
                  :key="i"
                  :value="province.id"
                >
                  {{ province.name.th }}
                </option>
              </select>
            </div>
          </div>

          <div class="w-full lg:w-4/12 px-4 pt-2">
            <div class="relative w-full">
              <label
                class="
                  block
                  uppercase
                  text-text_color_1-600
                  text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("users.form.district") }}
              </label>
              <select
                v-model="seletedDistrict"
                class="
                  border-0
                  border-border_color_1-500
                  px-3
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  ease-linear
                  transition-all
                  duration-150
                  w-full
                "
              >
                <option
                  v-for="(district, i) in validDistricts"
                  :key="i"
                  :value="district.id"
                >
                  {{ district.name.th }}
                </option>
              </select>
            </div>
          </div>

          <div class="w-full lg:w-4/12 px-4 pt-2">
            <div class="relative w-full">
              <label
                class="
                  block
                  uppercase
                  text-text_color_1-600
                  text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("users.form.sub_district") }}
              </label>
              <select
                v-model="seletedSubDistrict"
                class="
                  border-0
                  border-border_color_1-500
                  px-3
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  ease-linear
                  transition-all
                  duration-150
                  w-full
                "
              >
                <option
                  v-for="(subDistrict, i) in validSubDistricts"
                  :key="i"
                  :value="subDistrict.id"
                >
                  {{ subDistrict.name.th }}
                </option>
              </select>
            </div>
          </div>

          <div class="w-full px-4 pt-2">
            <div class="relative w-full">
              <label
                class="
                  block
                  uppercase
                  text-text_color_1-600
                  text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("users.form.address_1") }}
              </label>
              <input
                v-model="form.address_1"
                type="text"
                class="
                  border-0
                  px-3
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>

          <div class="w-full lg:w-4/12 px-4 pt-2">
            <div class="relative w-full">
              <label
                class="
                  block
                  uppercase
                  text-text_color_1-600
                  text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("users.form.post_code") }}
              </label>
              <input
                v-model="form.post_code"
                type="text"
                class="
                  border-0
                  px-3
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>
        </div>

        <div class="flex flex-wrap mt-20">
          <button
            class="
              bg-btn_green_color_active-500
              text-text_color_white
              active:bg-btn_green_color_active-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            type="submit"
          >
            <i class="fas fa-save"></i> {{ $t("app.button.save") }}
          </button>
          <button
            v-if="model"
            class="
              bg-btn_gray_color_active-50
              text-text_color_1-600
              active:bg-btn_gray_color_active-200
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            @click.prevent="reset"
          >
            <i class="fas fa-undo-alt"></i> {{ $t("app.button.reset") }}
          </button>
        </div>
      </form>
    </div>
  </nav>
</template>
); }

<script>
import Datepicker from "vue3-datepicker";

export default {
  data() {
    return {
      form: {},
      seletedProvince: null,
      seletedDistrict: null,
      seletedSubDistrict: null,
      validDistricts: [],
      validSubDistricts: [],
      today: new Date(),
    };
  },
  emits: ["close", "onSubmitUpdate", "onSubmitCreate"],
  components: {
    Datepicker,
  },
  props: {
    active: {
      default: false,
    },
    model: {
      default: null,
    },
    provinces: {
      default: [],
    },
    districts: {
      default: [],
    },
    subDistricts: {
      default: [],
    },
  },
  methods: {
    save() {
      const sProvince = this.provinces.find(
        (p) => p.id == this.seletedProvince
      );
      const provinceName = sProvince ? sProvince.name.th : "";
      const sDistrict = this.districts.find(
        (d) => d.id == this.seletedDistrict
      );
      const districtName = sDistrict ? sDistrict.name.th : "";
      const sSubDistrict = this.subDistricts.find(
        (s) => s.id == this.seletedSubDistrict
      );
      const subDistrictName = sSubDistrict ? sSubDistrict.name.th : "";

      if (this.model) {
        this.$emit("onSubmitUpdate", {
          ...this.form,
          province: provinceName,
          district: districtName,
          sub_district: subDistrictName,
          username:
            this.model.username || Math.random().toString(36).substring(7),
          password:
            this.model.password || Math.random().toString(36).substring(7),
          is_customer: true,
          birth_date: this.$moment(this.form.birth_date_fmt)
            .set("hour", 0)
            .set("minute", 0)
            .set("second", 0)
            .format()
            .split("+")[0],
          birth_date_str: this.formatDate(
            this.form.birth_date_fmt,
            "YYYY-MM-DD"
          ),
        });
      } else {
        this.$emit("onSubmitCreate", {
          ...this.form,
          province: provinceName,
          district: districtName,
          sub_district: subDistrictName,
          username: Math.random().toString(36).substring(7),
          password: Math.random().toString(36).substring(7),
          is_customer: true,
          birth_date: this.$moment(this.form.birth_date_fmt)
            .set("hour", 0)
            .set("minute", 0)
            .set("second", 0)
            .format()
            .split("+")[0],
          birth_date_str: this.formatDate(
            this.form.birth_date_fmt,
            "YYYY-MM-DD"
          ),
        });
      }
      if (this.model) {
        this.form = {
          ...this.model,
          birth_date_fmt: new Date(this.model.birth_date),
          birth_date_str: this.formatDate(this.model.birth_date, "YYYY-MM-DD"),
        };
        const oProvince = this.provinces.find(
          (p) => p.name && p.name.th == this.model.province
        );
        const provinceId = oProvince ? oProvince.id : null;
        const sDistrict = this.validDistricts.find(
          (d) =>
            d.changwat_id == provinceId &&
            d.name &&
            d.name.th == this.model.district
        );
        const districtId = sDistrict ? sDistrict.id : null;
        const sSubDistrict = this.validSubDistricts.find(
          (s) =>
            s.changwat_id == provinceId &&
            s.amphoe_id == sDistrict &&
            s.name &&
            s.name.th == this.seletedSubDistrict
        );
        const subDistrictId = sSubDistrict ? sSubDistrict.id : null;

        this.seletedProvince = provinceId;
        this.seletedDistrict = districtId;
        this.seletedSubDistrict = subDistrictId;
      } else {
        this.form = {};
      }
      this.$emit("close");
    },
    reset() {
      let confirmed = window.confirm(`${this.$t("app.confirm-reset")}`);
      if (confirmed) {
        if (this.model) {
          this.form = {
            ...this.model,
            birth_date_fmt: new Date(this.model.birth_date),
            birth_date_str: this.formatDate(
              this.model.birth_date,
              "YYYY-MM-DD"
            ),
          };

          const oProvince = this.provinces.find(
            (p) => p.name && p.name.th == this.model.province
          );
          const provinceId = oProvince ? oProvince.id : null;
          const sDistrict = this.validDistricts.find(
            (d) =>
              d.changwat_id == provinceId &&
              d.name &&
              d.name.th == this.model.district
          );
          const districtId = sDistrict ? sDistrict.id : null;
          const sSubDistrict = this.validSubDistricts.find(
            (s) =>
              s.changwat_id == provinceId &&
              s.amphoe_id == sDistrict &&
              s.name &&
              s.name.th == this.seletedSubDistrict
          );
          const subDistrictId = sSubDistrict ? sSubDistrict.id : null;

          this.seletedProvince = provinceId;
          this.seletedDistrict = districtId;
          this.seletedSubDistrict = subDistrictId;
        } else {
          this.form = {};
        }
      }
    },
    formatDate(val, format) {
      if (!val) return "-";
      return this.$moment(val)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .format(format)
        .split("+")[0];
    },
  },
  watch: {
    model(val) {
      if (val) {
        this.form =
          {
            ...val,
            birth_date_fmt: new Date(val.birth_date),
            birth_date_str: this.formatDate(val.birth_date, "YYYY-MM-DD"),
          } || {};
      } else {
        this.form = {};
      }
    },
    seletedProvince(val) {
      if (!val) {
        this.validDistricts = [];
      } else {
        this.validDistricts = this.districts.filter((d) => {
          return d.changwat_id == val;
        });
      }
    },
    seletedDistrict(val) {
      if (!val) {
        this.validSubDistricts;
      } else {
        this.validSubDistricts = this.subDistricts.filter((s) => {
          return s.amphoe_id == val;
        });
      }
    },
    seletedSubDistrict(val) {
      if (val) {
        const subD = this.validSubDistricts.find((s) => s.id == val);
        if (subD.zipcode) {
          this.form.post_code = subD.zipcode;
        }
      }
    },
  },
};
</script>

<style scoped>
.right-nav {
  left: auto;
  right: 0;
  width: 50%;
  max-width: 50%;
}

@media (max-width: 768px) {
  .right-nav {
    left: auto;
    right: 0;
    width: 100%;
    max-width: 100%;
  }
}

.active {
  transform: translatex(0);
  transition: all 0.2s ease-out;
}

.inactive {
  transform: translatex(100%);
  transition: all 0.2s ease-out;
}
</style>