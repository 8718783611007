<template>
  <div class="container px-4 min-h-screen">
    <loader :model="loading" :detail="error" @close="loading = null; error = null;" />
    <Head />
    <Detail
      :model="selectedData"
      @edit="showForm"
      @delete="deleteStaff"
      @close="closeDetail"
    />
    <Form
      :active="isShowForm"
      :model="editingData"
      :departments="departments"
      @close="closeForm"
      @onSubmitUpdate="updateStaff"
      @onSubmitCreate="createStaff"
    />
    <ResetPassword
      :active="isShowChangePassword"
      :model="editingData"
      @onSubmit="changePassword"
      @close="closeChangePassword"
    />
    <Department
      :active="isShowDepartment"
      @close="closeDepartment"
      :records="departments"
      :model="currentDepartment"
      @onSubmit="updateDepartments"
    />
    <div class="flex flex-wrap">
      <Search @onApply="applySearch" />
    </div>
    <div class="flex flex-wrap">
      <Table
        name="Staffs"
        :records="records"
        :page="page"
        :totalPage="totalPage"
        @onRecClick="showDetail"
        @onAddClick="() => showForm(null)"
        @onPageChange="
          (toPage) => {
            page = toPage;
            fetchStaff();
          }
        "
        @onSortChange="
          (query) => {
            ordering = query;
            fetchStaff();
          }
        "
        @onChangePasswordClick="showChangePassword"
        @onDepartmentClick="showDepartment"
      />
    </div>
  </div>
</template>
<script>
import Head from "@/components/Staffs/Head.vue";
import Table from "@/components/Staffs/Table.vue";
import Search from "@/components/Staffs/Search.vue";
import Detail from "@/components/Staffs/Detail.vue";
import Form from "@/components/Staffs/Form.vue";
import ResetPassword from "@/components/Staffs/ResetPassword.vue";
import Department from "@/components/Staffs/Department.vue";

import { StaffService, DepartmentService } from "@/services";

export default {
  name: "staff-page",
  data() {
    return {
      selectedData: null,
      editingData: null,
      isShowForm: false,
      isShowChangePassword: false,
      isShowDepartment: false,
      records: [],
      departments: [],
      currentDepartment: [],
      page: 1,
      totalPage: 1,
      search: null,
      ordering: null,
      loading: null,
      error: null,
    };
  },
  components: {
    Head,
    Table,
    Search,
    Detail,
    Form,
    ResetPassword,
    Department,
  },
  async mounted() {
    this.loading = "load";
    try {
      const data = await StaffService.list();
      this.records = data.results;
      this.page = data.page;
      this.totalPage = data.totalPage;
      const userData = await DepartmentService.list({
        page_size: 9999,
      });
      this.users = userData.results;
      const departmentData = await DepartmentService.list({
        page_size: 9999,
      });
      this.departments = departmentData.results;
      this.loading = "success";
    } catch (e) {
      this.loading = "fail";
      this.error = e.message;
    }
  },
  methods: {
    async fetchStaff() {
      const data = await StaffService.list({
        page: this.page,
        search: this.search,
        ordering: this.ordering,
      });
      this.records = data.results;
      this.page = data.page;
      this.totalPage = data.totalPage;
    },
    applySearch(form) {
      this.loading = "load";
      try {
        this.search = form.keyword || null;
        this.page = 1;
        this.fetchStaff();
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    showDetail(data) {
      this.closeForm();
      this.closeChangePassword();
      this.closeDepartment();

      let isSwitch = this.selectedData && data != this.selectedData;
      if (isSwitch) {
        this.closeDetail();
        setTimeout(() => {
          this.selectedData = data;
        }, 300);
      } else if (data == this.selectedData) {
        this.closeDetail();
      } else {
        this.selectedData = data;
      }
    },
    closeDetail() {
      this.selectedData = null;
    },
    showForm(data) {
      this.closeDetail();
      this.closeChangePassword();
      this.closeDepartment();

      if (this.isShowForm) {
        this.closeForm();
        setTimeout(() => {
          this.isShowForm = true;
          this.editingData = data;
        }, 300);
      } else {
        this.isShowForm = true;
        this.editingData = data;
      }
    },
    closeForm() {
      this.isShowForm = false;
      this.editingData = null;
    },
    async deleteStaff(data) {
      let confirmed = window.confirm(
        `${this.$t("app.confirm-delete")} \n(${this.$t(
          "sidebar.menu.stafsf"
        )} ${data.username})`
      );
      if (confirmed) {
        this.loading = "load";
        try {
          await StaffService.delete(data.id);
          this.fetchStaff();
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }

      this.closeDetail();
    },
    async updateStaff(data) {
      let confirmed = window.confirm(
        `${this.$t("app.confirm-update")} \n(${this.$t(
          "sidebar.menu.stafsf"
        )} ${data.username})`
      );
      if (confirmed) {
        await StaffService.update(data.id, data);
        this.fetchStaff();
      }
      this.closeForm();
    },
    async createStaff(data) {
      this.loading = "load";
      try {
        await StaffService.create(data);
        this.fetchStaff();
        this.closeForm();
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    showChangePassword(data) {
      this.closeDetail();
      this.closeForm();
      this.closeDepartment();

      if (this.isShowChangePassword) {
        this.closeChangePassword();
        setTimeout(() => {
          this.isShowChangePassword = true;
          this.editingData = data;
        }, 300);
      } else {
        this.isShowChangePassword = true;
        this.editingData = data;
      }
    },
    closeChangePassword() {
      this.isShowChangePassword = false;
      this.editingData = null;
    },
    async changePassword(newPassword) {
      let confirmed = window.confirm(
        `${this.$t("app.confirm-update")} \n(${this.$t("sidebar.menu.staff")} ${
          this.editingData.username
        })`
      );
      if (confirmed) {
        this.loading = "load";
        try {
          await StaffService.changePassword(this.editingData.id, {
            newPassword,
          });
          this.fetchStaff();
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }
      this.closeChangePassword();
    },
    showDepartment(data) {
      this.closeDetail();
      this.closeForm();
      this.closeChangePassword();

      if (data) {
        this.currentDepartment = data.departments.reduce((map, d) => {
          map[d.id] = true;
          return map;
        }, {});
      }

      if (this.isShowDepartment) {
        this.closeDepartment();
        setTimeout(() => {
          this.isShowDepartment = true;
          this.editingData = data;
        }, 300);
      } else {
        this.isShowDepartment = true;
        this.editingData = data;
      }
    },
    closeDepartment() {
      this.isShowDepartment = false;
      this.editingData = null;
    },
    async updateDepartments(data) {
      let confirmed = window.confirm(
        `${this.$t("app.confirm-update")} \n(${this.$t("sidebar.menu.staff")} ${
          this.editingData.username
        })`
      );
      if (confirmed) {
        this.loading = "load";
        try {
          await StaffService.setDepartments(this.editingData.id, data);
          this.fetchStaff();
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }
      this.closeDepartment();
    },
  },
};
</script>
