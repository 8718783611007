<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded
      bg-bg_color_white
    "
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center justify-between">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <div class="flex items-center gap-6 justify-between md:justify-start">
            <h3 class="font-semibold text-lg text-text_color_1-700">
              {{ $t("app.table-title") }}
            </h3>
            <span class="ml-4 font-thin text-xs">
              <a
                class="px-4 py-4"
                @click="prevPage"
                :class="page > 1 ? 'cursor-pointer' : 'text-text_color_1-400'"
                ><i class="fas fa-chevron-left"
              /></a>
              {{ page }} / {{ totalPage }}
              <a
                class="px-4 py-4"
                :class="
                  page < totalPage ? 'cursor-pointer' : 'text-text_color_1-400'
                "
                @click="nextPage"
                ><i class="fas fa-chevron-right" /></a
            ></span>
          </div>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
              "
            >
              {{ $t("coupon_usages.table.admin") }}
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
              "
            >
              {{ $t("coupon_usages.table.user") }}
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
              "
            >
              {{ $t("coupon_usages.table.code") }}
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
              "
            >
              {{ $t("coupon_usages.table.coupon") }}
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
                cursor-pointer
              "
              @click="sorting('used_at')"
            >
              {{ $t("coupon_usages.table.used_at") }}
              <i class="fas" :class="getSortClass('used_at')"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(coupon_usage, i) in records" :key="i">
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
                text-left
                items-center
              "
            >
              <span class="ml-3 font-bold">
                {{
                  `${coupon_usage.admin.first_name} ${coupon_usage.admin.last_name}`
                }}
              </span>
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              <div class="flex text-left items-center">
                <img
                  :src="
                    (coupon_usage.user && coupon_usage.user.line_picture_url) ||
                    defaultAvatar
                  "
                  class="h-12 w-12 bg-bg_color_white rounded-full border"
                  alt="avatar"
                />
                <span class="ml-3 font-bold">
                  {{
                    `${
                      (coupon_usage.user && coupon_usage.user.first_name) || "-"
                    } ${
                      (coupon_usage.user && coupon_usage.user.last_name) || "-"
                    }`
                  }}
                </span>
              </div>
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              <span class="ml-3 font-bold">
                {{ coupon_usage.code }}
              </span>
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              <span class="ml-3 font-bold">
                {{ coupon_usage.coupon.name }}
              </span>
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              {{ formatDate(coupon_usage.used_at, "DD MMMM YYYY HH:mm") }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import defaultAvatar from "@/assets/img/default-avatar.jpg";

export default {
  data() {
    return {
      defaultAvatar,
      defaultSortStatus: {
        used_at: 0
      },
      sortStatus: {
        used_at: 0
      },
    };
  },
  emits: ["onAddClick", "onRecClick", "onSortChange", "onPageChange "],
  props: {
    records: {
      default: [],
    },
    page: {
      default: 1,
    },
    totalPage: {
      default: 1,
    },
  },
  methods: {
    formatDate(val, format) {
      if (!val) return "-";
      return this.$moment(val).locale("th").format(format);
    },
    getSortClass(field) {
      return ["fa-sort", "fa-sort-up", "fa-sort-down"][
        this.sortStatus[field] || 0
      ];
    },
    sorting(field) {
      const nextState =
        this.sortStatus[field] >= 2 ? 0 : this.sortStatus[field] + 1;
      this.sortStatus = { ...this.defaultSortStatus };
      this.sortStatus[field] = nextState;
      const orderingQuery = [null, `${field}`, `-${field}`][nextState];
      this.$emit("onSortChange", orderingQuery);
    },
    prevPage() {
      if (this.page > 1) this.$emit("onPageChange", this.page - 1);
    },
    nextPage() {
      if (this.page < this.totalPage) this.$emit("onPageChange", this.page + 1);
    },
  },
};
</script>
