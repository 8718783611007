<template>
  <div>
    <a
      class="text-text_color_1-500 block"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-text_color_white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <a
        class="text-sm py-2 px-4 font-normal block w-full cursor-pointer whitespace-nowrap bg-transparent text-text_color_1-700 hover:text-text_color_1-400"
        @click.prevent="clickStaff"
      >
        {{$t('departments.dropdown.staff')}}
      </a>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

export default {
  data() {
    return {
      dropdownPopoverShow: false
    };
  },
  emits: ['onCouponClick', 'onStaffClick'],
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    clickStaff() {
      this.dropdownPopoverShow = false;
      this.$emit('onStaffClick');
    }
  },
};
</script>
