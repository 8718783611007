import BaseRepo from '@/repos/BaseRepo';

const API_PATH = '/bo/users/';

class UserRepo {
    list(params) {
        return BaseRepo.get(API_PATH, { params });
    }

    get(id, params) {
        return BaseRepo.get(`${API_PATH}${id}/`, { params });
    }

    create(user) {
        return BaseRepo.post(API_PATH, user);
    }

    update(id, user) {
        return BaseRepo.put(`${API_PATH}${id}/`, user);
    }

    delete(id) {
        return BaseRepo.delete(`${API_PATH}${id}/`);
    }

    import(file) {
        const formData = new FormData();
        formData.append("file", file);
        return BaseRepo.post("/account/import/user/", formData);
    }

    setEvents(id, events) {
        return BaseRepo.post(`${API_PATH}${id}/events/`, events);
    }

    setSegments(id, segments) {
        return BaseRepo.post(`${API_PATH}${id}/segments/`, segments);
    }

    addAccPurchase(id, form) {
        return BaseRepo.post(`${API_PATH}${id}/acc_purchases/`, form);
    }
}

export default new UserRepo();