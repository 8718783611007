<template>
  <nav
    class="
      right-nav
      fixed
      top-0
      bottom-0
      overflow-y-auto overflow-hidden
      shadow-xl
      bg-bg_color_white
      w-64
      z-60
      py-4
      px-6
      h-full
    "
    :class="model ? 'active' : 'inactive'"
  >
    <div class="flex justify-start">
      <a
        class="
          text-text_color_1-400
          block
          cursor-pointer
          px-4
          py-4
          border
          border-border_color_1-400
          rounded-lg
        "
        @click="$emit('close')"
      >
        <i class="fas fa-angle-double-right"></i>
      </a>
    </div>

    <div v-if="model" class="flex flex-col items-center">
      <div class="flex flex-wrap justify-center">
        <div class="w-full px-4 gap-x-4 flex justify-center">
          <div class="relative">
            <img
              alt="avatar"
              :src="model.url || defaultCoupon"
              class="shadow-xl h-auto align-middle border-none max-w-150-px"
            />
          </div>
          <div class="relative">
            <img
              alt="avatar"
              :src="model.thumbnail_url || defaultCoupon"
              class="shadow-xl h-auto align-middle border-none max-w-150-px"
            />
          </div>
        </div>

        <!-- Info -->
        <div class="w-full px-4 md:px-8 pt-8">
          <div class="mb-2 text-text_color_1-600">
            <span class="min-w-80-px inline-block text-right pr-2">
              <span class="font-bold text-text_color_1-400">{{
                $t("coupons.form.name")
              }}</span>
            </span>
            {{ `${model.name} (${model.code})` }}
          </div>

          <div class="mb-2 text-text_color_1-600">
            <span class="min-w-80-px inline-block text-right pr-2">
              <span class="font-bold text-text_color_1-400">{{
                $t("coupons.form.type")
              }}</span>
            </span>
            {{
              $t(`coupons.table.type_value_${model.type}`) ||
              $t("coupons.table.type_value_other")
            }}
          </div>

          <div class="mb-2 text-text_color_1-600">
            <span class="min-w-80-px inline-block text-right pr-2">
              <span class="font-bold text-text_color_1-400">{{
                $t("coupons.form.collect_method")
              }}</span>
            </span>
            {{
              $t(
                `coupons.table.collect_method_value_${model.collect_method}`
              ) || $t("coupons.table.collect_method_value_other")
            }}
          </div>

          <div class="mb-2 text-text_color_1-600">
            <span class="min-w-80-px inline-block text-right pr-2">
              <span class="font-bold text-text_color_1-400">{{
                $t("coupons.form.num_time")
              }}</span>
            </span>
            {{ model.num_time }}
          </div>

          <div class="mb-2 text-text_color_1-600">
            <span class="min-w-80-px inline-block text-right pr-2">
              <span class="font-bold text-text_color_1-400">{{
                $t("coupons.form.num_limit_time")
              }}</span>
            </span>
            {{ model.num_limit_time }}
          </div>

          <div class="mb-2 text-text_color_1-600">
            <span class="min-w-80-px inline-block text-right pr-2">
              <span class="font-bold text-text_color_1-400">{{
                $t("coupons.form.used_limit_time")
              }}</span>
            </span>
            {{ model.used_limit_time }}
          </div>

          <div class="mb-2 text-text_color_1-600">
            <span class="min-w-80-px inline-block text-right pr-2">
              <span class="font-bold text-text_color_1-400">{{
                $t("coupons.form.collect_expired_at")
              }}</span>
            </span>
            {{ formatDate(model.collect_expired_at, "DD MMMM YYYY") }}
          </div>

          <div class="mb-2 text-text_color_1-600">
            <span class="min-w-80-px inline-block text-right pr-2">
              <span class="font-bold text-text_color_1-400">{{
                $t("coupons.form.expired_at")
              }}</span>
            </span>
            {{ formatDate(model.expired_at, "DD MMMM YYYY") }}
          </div>
        </div>

        <!-- Description -->
        <div class="w-full px-4 md:px-8">
          <hr class="my-6 border-b-1 border-border_color_1-300" />
          <div class="mb-2 text-text_color_1-600">
            <span class="mb-2 min-w-80-px inline-block pr-2">
              <span class="font-bold text-text_color_1-400">{{
                $t("coupons.form.description")
              }}</span>
            </span>
            <editor v-model="description" disabledToolbar disabled />
          </div>
        </div>

        <!-- Condition -->
        <div class="w-full px-4 md:px-8">
          <hr class="my-6 border-b-1 border-border_color_1-300" />
          <div class="mb-2 text-text_color_1-600">
            <span class="mb-2 min-w-80-px inline-block pr-2">
              <span class="font-bold text-text_color_1-400">{{
                $t("coupons.form.condition")
              }}</span>
            </span>
            <editor v-model="condition" disabledToolbar disabled />
          </div>
        </div>

        <!-- Action -->
        <div class="flex flex-wrap gap-6 justify-center mt-20">
          <button
            class="
              bg-btn_yellow_color_active-500
              text-text_color_white
              active:bg-btn_yellow_color_active-600
              font-bold
              uppercase
              text-xs
              px-8
              py-4
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            type="button"
            @click="$emit('edit', model)"
          >
            <i class="fas fa-edit"></i> {{ $t("app.button.edit") }}
          </button>
          <button
            class="
              bg-btn_red_color_active-500
              text-text_color_white
              active:bg-btn_red_color_active-600
              font-bold
              uppercase
              text-xs
              px-8
              py-4
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            type="button"
            @click="$emit('delete', model)"
          >
            <i class="fas fa-trash"></i> {{ $t("app.button.delete") }}
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>
); }

<script>
import defaultCoupon from "@/assets/img/default-coupon.jpg";
import Editor from "@/components/Common/Editor.vue";

export default {
  data() {
    return {
      defaultCoupon,
      description: "",
      condition: "",
    };
  },
  props: {
    model: {
      default: null,
    },
  },
  components: {
    Editor,
  },
  emits: ["onCardClick"],
  methods: {
    formatDate(val, format) {
      if (!val) return "-";
      return this.$moment(val).locale("th").format(format);
    },
    onCardClick(card) {
      this.$emit("onCardClick", card);
    },
  },
  watch: {
    model(value) {
      let model = { ...value };
      this.description = model.description;
      this.condition = model.condition;
    },
  },
  mounted() {
    if (this.model) {
      this.description = this.model.description;
      this.condition = this.model.condition;
    }
  },
};
</script>

<style scoped>
.right-nav {
  left: auto;
  right: 0;
  width: 70%;
  max-width: 70%;
}

@media (max-width: 768px) {
  .right-nav {
    left: auto;
    right: 0;
    width: 100%;
    max-width: 100%;
  }
}

.active {
  transform: translatex(0);
  transition: all 0.2s ease-out;
}

.inactive {
  transform: translatex(100%);
  transition: all 0.2s ease-out;
}
</style>