import BaseRepo from '@/repos/BaseRepo';

const API_PATH = '/bo/events/';

class EventRepo {
    list(params) {
        return BaseRepo.get(API_PATH, { params });
    }

    get(id, params) {
        return BaseRepo.get(`${API_PATH}${id}/`, { params });
    }

    create(user) {
        return BaseRepo.post(API_PATH, user);
    }

    update(id, user) {
        return BaseRepo.put(`${API_PATH}${id}/`, user);
    }

    delete(id) {
        return BaseRepo.delete(`${API_PATH}${id}/`);
    }

    setCards(id, cards) {
        return BaseRepo.post(`${API_PATH}${id}/cards/`, cards);
    }
}

export default new EventRepo();