<template>
  <nav
    class="
      right-nav
      fixed
      top-0
      bottom-0
      overflow-y-auto overflow-hidden
      shadow-xl
      bg-bg_color_1-100
      w-64
      z-65
      py-4
      px-6
      h-full
    "
    :class="active ? 'active' : 'inactive'"
  >
    <div class="flex justify-start">
      <a
        class="
          text-text_color_1-400
          block
          cursor-pointer
          px-4
          py-4
          border
          border-border_color_1-400
          rounded-lg
        "
        @click="$emit('close')"
      >
        <i class="fas fa-angle-double-right"></i>
      </a>
    </div>
    <div v-if="active" class="flex flex-col items-center">
      <div class="w-full px-4 flex justify-center">
        <h3
          class="
            text-xl
            font-semibold
            leading-normal
            text-text_color_1-700
            mb-2
          "
        >
          {{ $t("users.event.title") }}
        </h3>
      </div>
      <div class="flex flex-wrap justify-start">
        <div class="w-full px-4 pt-2">
          <div class="flex w-full items-center my-4">
            <label
              class="
                block
                uppercase
                text-text_color_1-600
                text-xs
                font-bold
                mr-4
              "
            >
              {{$t('users.event.choose')}}
            </label>
            <select
              v-model="form.event_id"
              class="
                border-0
                px-3
                py-1
                placeholder-placeholder_color_1-300
                text-text_color_1-600
                bg-bg_color_white
                rounded
                text-sm
                shadow
                focus:outline-none
                focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            >
              <option
                v-for="(event, i) in records"
                :key="i"
                :value="event.id"
              >
                {{ `${event.member_card_set_code}` }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex flex-wrap mt-20">
          <button
            class="
              bg-btn_green_color_active-500
              text-text_color_white
              active:bg-btn_green_color_active-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            @click="save"
          >
            <i class="fas fa-save"></i> {{ $t("app.button.save") }}
          </button>
          <button
            class="
              bg-btn_gray_color_active-50
              text-text_color_1-600
              active:bg-btn_gray_color_active-200
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            @click.prevent="reset"
          >
            <i class="fas fa-undo-alt"></i> {{ $t("app.button.reset") }}
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>
); }

<script>
export default {
  data() {
    return {
      form: {},
    };
  },
  props: {
    active: {
      default: false,
    },
    records: {
      default: []
    },
    model: {
      default: null,
    },
  },
  emits: ["close", "onSubmit"],
  methods: {
    save() {
      this.$emit("onSubmit", this.form);
    },
    reset() {
      let confirmed = window.confirm(`${this.$t("app.confirm-reset")}`);
      if (confirmed) {
        this.form = { event_id: this.model };
      }
    },
  },
  watch: {
    model(val) {
      this.form = { event_id: val };
    },
  },
};
</script>

<style scoped>
.right-nav {
  left: auto;
  right: 0;
  width: 30%;
  max-width: 30%;
}

@media (max-width: 768px) {
  .right-nav {
    left: auto;
    right: 0;
    width: 100%;
    max-width: 100%;
  }
}

.active {
  transform: translatex(0);
  transition: all 0.2s ease-out;
}

.inactive {
  transform: translatex(100%);
  transition: all 0.2s ease-out;
}
</style>