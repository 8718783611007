import axios from 'axios'
import { baseUrl } from '@/configs/api.config'

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  }
})

instance.interceptors.request.use(function (config) {
  if (localStorage.getItem("access_token")) {
    config.headers['Authorization'] = `token ${localStorage.getItem("access_token")}`
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});


instance.interceptors.response.use(response => response, error => {
  const status = error.response ? error.response.status : null

  if (status === 401 || status === 403) {
    localStorage.removeItem("access_token");
    window.location.href = "/"
  }

  return Promise.reject(error);
});

export default instance;