<template>
  <nav
    class="
      right-nav
      fixed
      top-0
      bottom-0
      overflow-y-auto overflow-hidden
      shadow-xl
      bg-bg_color_1-100
      w-64
      z-65
      py-4
      px-6
      h-full
    "
    :class="active ? 'active' : 'inactive'"
  >
    <div class="flex justify-start">
      <a
        class="
          text-text_color_1-400
          block
          cursor-pointer
          px-4
          py-4
          border
          border-border_color_1-400
          rounded-lg
        "
        @click="$emit('close')"
      >
        <i class="fas fa-angle-double-right"></i>
      </a>
    </div>
    <div v-if="active" class="flex flex-col items-center">
      <div class="w-full px-4 flex justify-center">
        <h3
          class="
            text-xl
            font-semibold
            leading-normal
            text-text_color_1-700
            mb-2
          "
        >
          {{ $t("events.cards.title") }}
        </h3>
      </div>
      <div class="flex flex-wrap justify-start">
        <div class="w-full px-4 pt-2">
          <div
            v-for="level in maxLevel"
            :key="level"
            class="flex w-full items-center my-4"
          >
            <label
              class="
                mr-4
                whitespace-nowrap
                block
                uppercase
                text-text_color_1-600
                font-bold
              "
            >
              {{ ` ${$t("cards.form.level")}  ${level}` }}
            </label>
            <select
              v-model="form[level]"
              class="
                border-0
                px-3
                py-1
                placeholder-placeholder_color_1-300
                text-text_color_1-600
                bg-bg_color_white
                rounded
                text-sm
                shadow
                focus:outline-none
                focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            >
              <option
                v-for="(card, i) in getFilteredCardsByLevel(level)"
                :key="i"
                :value="card.id"
              >
                {{ `${card.name} (${card.point} pt.)` }}
              </option>
            </select>
            <button
              v-if="level > 1 && level == maxLevel"
              class="
                bg-btn_red_color_active-500
                text-text_color_white
                active:bg-btn_red_color_active-600
                font-bold
                uppercase
                text-xs
                px-4
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                ml-2
                ease-linear
                transition-all
                duration-150
              "
              @click="
                () => {
                  maxLevel = maxLevel - 1;
                  delete form[level];
                }
              "
            >
              <i class="fas fa-minus-circle" />
            </button>
          </div>
          <button
            v-if="maxLevel < 3"
            class="
              bg-btn_blue_color_active-500
              text-text_color_white
              active:bg-btn_blue_color_active-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            @click="
              () => {
                maxLevel = maxLevel + 1;
              }
            "
          >
            <i class="fas fa-plus-circle" />
          </button>
        </div>

        <div class="flex flex-wrap mt-10">
          <button
            class="
              bg-btn_green_color_active-500
              text-text_color_white
              active:bg-btn_green_color_active-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            @click="save"
          >
            <i class="fas fa-save"></i> {{ $t("app.button.save") }}
          </button>
          <button
            class="
              bg-btn_gray_color_active-50
              text-text_color_1-600
              active:bg-btn_gray_color_active-200
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            @click.prevent="reset"
          >
            <i class="fas fa-undo-alt"></i> {{ $t("app.button.reset") }}
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>
); }

<script>
import defaultAvatar from "@/assets/img/default-avatar.jpg";

export default {
  data() {
    return {
      defaultAvatar,
      form: {},
      maxLevel: 1,
    };
  },
  props: {
    active: {
      default: false,
    },
    records: {
      default: [],
    },
    model: {
      default: [],
    },
  },
  emits: ["onSubmit"],
  methods: {
    save() {
      const cardId = Object.values(this.form).filter((id) => !!id);
      this.$emit("onSubmit", cardId);
    },
    reset() {
      let confirmed = window.confirm(`${this.$t("app.confirm-reset")}`);
      if (confirmed) {
        this.form = this.model.reduce((map, card) => {
          map[card.level] = card.id;
          return map;
        }, {});
        this.maxLevel = Math.max.apply(
          null,
          this.model.filter((card) => !!card).map((card) => card.level)
        );
      }
    },
    getFilteredCardsByLevel(level) {
      if(level <= 1) return this.masterCards[level];

      const prevCard = this.masterCards[level-1].find(card => card.id === this.form[level-1]);
      if(!prevCard) return this.masterCards[level];

      return this.masterCards[level].filter(card => card.point > prevCard.point)
    }
  },
  computed: {
    masterCards: function () {
      if (!this.records || !this.records.length) return {};
      return this.records.reduce((map, card) => {
        (map[card.level] = map[card.level] || []).push(card);
        return map;
      }, {});
    },
  },
  watch: {
    model(val) {
      if (!val || !val.length) {
        this.form = {};
        this.maxLevel = 1;
      } else {
        this.form = val.reduce((map, card) => {
          map[card.level] = card.id;
          return map;
        }, {});
        this.maxLevel = Math.max.apply(
          null,
          val.filter((card) => !!card).map((card) => card.level)
        );
        console.log(val, this.maxLevel);
      }
    },
  },
};
</script>

<style scoped>
.right-nav {
  left: auto;
  right: 0;
  width: 50%;
  max-width: 50%;
}

@media (max-width: 768px) {
  .right-nav {
    left: auto;
    right: 0;
    width: 100%;
    max-width: 100%;
  }
}

.active {
  transform: translatex(0);
  transition: all 0.2s ease-out;
}

.inactive {
  transform: translatex(100%);
  transition: all 0.2s ease-out;
}
</style>