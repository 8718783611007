<template>
  <div
    class="loading-container flex justify-center items-center"
    :class="loadingClass"
    @click="close"
  >
    <svg
      v-if="model === 'load'"
      class="animate-spin w-24 h-24 text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="#888888"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>

    <svg
      v-if="model === 'success'"
      class="checkmark w-24 h-24 "
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
    >
      <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
      <path
        class="checkmark__check"
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8"
      />
    </svg>

  <div v-if="model === 'fail'">
    <svg
      v-if="model === 'fail'"
      class="crossmark w-24 h-24 "
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
    >
      <circle class="crossmark__circle" cx="26" cy="26" r="25" fill="none" />
      <path
        class="crossmark__cross"
        fill="none"
        d="M16 16 36 36 M36 16 16 36"
      />
    </svg>
    <div class="text-white text-2xl" v-if="error">{{error}}</div>
    </div>
  </div>
</template>
); }

<script>
export default {
  props: {
    model: {
      default: null,
    },
    detail: {
      default: null
    }
  },
  emits: ["close"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
  computed: {
    loadingClass: function () {
      if (!this.model) return;

      if (this.model == "load") {
        return "active";
      } else if (this.model == "success") {
        return "active";
      } else if (this.model == "fail") {
        return "active";
      } else {
        return "inactive";
      }
    },
    error: function () {
      return this.detail || this.$t("app.error.default");
    }
  },
  mounted() {
    if (this.model == "success") {
      setTimeout(this.close, 500);
    }
  },
  watch: {
    model(val) {
      if (val == "success") {
        setTimeout(this.close, 500);
      }
    },
  },
};
</script>

<style scoped>
.loading-container {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.active {
  width: 100%;
  height: 100%;
}

.inactive {
  width: 0;
  height: 0;
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.checkmark__circle {
  stroke-dasharray: 216;
  stroke-dashoffset: 216;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #10b981;
  fill: none;
}

.checkmark {
  width: 106px;
  height: 106px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px 80px #10b981;
  transform: none;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 98;
  stroke-dashoffset: 0;
}

.crossmark__circle {
  stroke-dasharray: 216;
  stroke-dashoffset: 216;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #ef4444;
  fill: none;
}

.crossmark {
  width: 106px;
  height: 106px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px 80px #ef4444;
  transform: none;
}

.crossmark__cross {
  transform-origin: 50% 50%;
  stroke-dasharray: 98;
  stroke-dashoffset: 0;
}
</style>