<template>
  <vue-editor
    class="w-full bg-bg_color_white"
    id="editor"
    v-model="data"
    :editorToolbar="customToolbar"
    :editorOptions="editorOptions"
  >
  </vue-editor>
</template>

<script>
import { VueEditor } from "vue3-editor";

export default {
  components: {
    VueEditor,
  },
  props: {
    disabledToolbar: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      data: "",
      editorOptions: this.disabledToolbar ? {
          modules: {
            toolbar: false,
          },
        } : {},
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ color: [] }],
        [{ header: [1, 2, 3, 4, false] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        ["clean"],
      ],
    };
  },
  watch: {
    modelValue(value) {
      const isSame = this.data === value;
      if (isSame) return;
      this.data = this.modelValue;
    },
    data(value) {
      const isSame = this.modelValue === value;
      if (isSame) return;
      this.$emit("update:modelValue", this.data);
    },
  },
  mounted() {
    this.data = this.modelValue;
  },
};
</script>