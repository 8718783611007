<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded
      bg-bg_color_white
    "
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center justify-between">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <div class="flex items-center gap-6 justify-between md:justify-start">
            <h3 class="font-semibold text-lg text-text_color_1-700">
              {{ $t("app.table-title") }}
            </h3>
            <span class="ml-4 font-thin text-xs">
              <a
                class="px-4 py-4"
                @click="prevPage"
                :class="page > 1 ? 'cursor-pointer' : 'text-text_color_1-400'"
                ><i class="fas fa-chevron-left"
              /></a>
              {{ page }} / {{ totalPage }}
              <a
                class="px-4 py-4"
                :class="
                  page < totalPage ? 'cursor-pointer' : 'text-text_color_1-400'
                "
                @click="nextPage"
                ><i class="fas fa-chevron-right" /></a
            ></span>
          </div>
        </div>

        <div
          class="
            flex
            justify-start
            md:justify-end
            w-full
            px-4
            mt-2
            md:mt-0
            max-w-full
            flex-grow flex-1
          "
        >
          <button
            class="
              bg-btn_blue_color_active-500
              text-text_color_white
              active:bg-btn_blue_color_active-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            type="button"
            @click="$emit('onImportClick')"
          >
            <i class="fas fa-file-import"></i> {{ $t("app.button.import") }}
          </button>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
              "
            >
              {{ $t("acc_purchases.table.user") }}
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
              "
            >
              {{ $t("acc_purchases.table.mobile") }}
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
              "
            >
              {{ $t("acc_purchases.table.store") }}
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
                cursor-pointer
              "
              @click="sorting('purchase_balance')"
            >
              {{ $t("acc_purchases.table.purchase_balance") }}
              <i class="fas" :class="getSortClass('purchase_balance')"></i>
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
              "
            >
              {{ $t("acc_purchases.table.special_promotion") }}
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
                cursor-pointer
              "
              @click="sorting('purchase_point')"
            >
              {{ $t("acc_purchases.table.purchase_point") }}
              <i class="fas" :class="getSortClass('purchase_point')"></i>
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
                cursor-pointer
              "
              @click="sorting('purchase_date')"
            >
              {{ $t("acc_purchases.table.purchase_date") }}
              <i class="fas" :class="getSortClass('purchase_date')"></i>
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
              "
            >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(acc_purchase, i) in records" :key="i">
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
                text-left
                items-center
              "
            >
              <span class="ml-3 font-bold">
                {{
                  `${acc_purchase.user_info && acc_purchase.user_info.first_name || "-"} ${acc_purchase.user_info && acc_purchase.user_info.last_name || "-"}`
                }}
              </span>
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              {{ `${acc_purchase.user_info && acc_purchase.user_info.mobile || "-"}` }}
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              <span class="ml-3 font-bold">
                {{ acc_purchase.store_name }}
              </span>
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              <span class="ml-3 font-bold">
                {{ acc_purchase.purchase_balance }}
              </span>
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              <span class="ml-3 font-bold">
                {{ acc_purchase.special_promotion }}
              </span>
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              <span class="ml-3 font-bold">
                {{ acc_purchase.purchase_point }}
              </span>
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              {{ formatDate(acc_purchase.purchase_date, "DD MMMM YYYY HH:mm") }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import defaultAvatar from "@/assets/img/default-avatar.jpg";

export default {
  data() {
    return {
      defaultAvatar,
      defaultSortStatus: {
        purchase_date: 0,
        purchase_balance: 0,
        purchase_point: 0
      },
      sortStatus: {
        purchase_date: 0,
        purchase_balance: 0,
        purchase_point: 0
      },
    };
  },
  emits: ["onImportClick", "onSortChange", "onPageChange "],
  props: {
    records: {
      default: [],
    },
    page: {
      default: 1,
    },
    totalPage: {
      default: 1,
    },
  },
  methods: {
    formatDate(val, format) {
      if (!val) return "-";
      return this.$moment(val).locale("th").format(format);
    },
    getSortClass(field) {
      return ["fa-sort", "fa-sort-up", "fa-sort-down"][
        this.sortStatus[field] || 0
      ];
    },
    sorting(field) {
      const nextState =
        this.sortStatus[field] >= 2 ? 0 : this.sortStatus[field] + 1;
      this.sortStatus = { ...this.defaultSortStatus };
      this.sortStatus[field] = nextState;
      const orderingQuery = [null, `${field}`, `-${field}`][nextState];
      this.$emit("onSortChange", orderingQuery);
    },
    prevPage() {
      if (this.page > 1) this.$emit("onPageChange", this.page - 1);
    },
    nextPage() {
      if (this.page < this.totalPage) this.$emit("onPageChange", this.page + 1);
    },
  },
};
</script>
