<template>
  <div class="container px-4 min-h-screen">
    <loader
      :model="loading"
      :detail="error"
      @close="
        loading = null;
        error = null;
      "
    />
    <Head />
    <Detail
      :model="selectedData"
      @edit="showForm"
      @delete="deleteCoupon"
      @close="closeDetail"
    />
    <Form
      :active="isShowForm"
      :model="editingData"
      :departments="departments"
      @close="closeForm"
      @onSubmitUpdate="updateCoupon"
      @onSubmitCreate="createCoupon"
    />
    <Broadcast
      :active="isShowBroadcast"
      :model="editingData"
      @close="closeBroadcast"
      @onSubmit="broadcastCoupon"
      :users="users"
      :segments="segments"
      :eventPackages="eventPackages"
      :cards="cards"
      :coupons="coupons"
      :health="health"
      :interest="interest"
      :provinces="provinceList"
    />
    <div class="flex flex-wrap">
      <Search @onApply="applySearch" />
    </div>
    <div class="flex flex-wrap">
      <Table
        name="Coupons"
        :records="records"
        :page="page"
        :totalPage="totalPage"
        @onRecClick="showDetail"
        @onAddClick="() => showForm(null)"
        @onPageChange="
          (toPage) => {
            page = toPage;
            fetchCoupon();
          }
        "
        @onSortChange="
          (query) => {
            ordering = query;
            fetchCoupon();
          }
        "
        @onBroadcastClick="showBroadcast"
      />
    </div>
  </div>
</template>
<script>
import Head from "@/components/Coupons/Head.vue";
import Table from "@/components/Coupons/Table.vue";
import Search from "@/components/Coupons/Search.vue";
import Detail from "@/components/Coupons/Detail.vue";
import Form from "@/components/Coupons/Form.vue";
import Broadcast from "@/components/Coupons/Broadcast.vue";
import provinces from "@/assets/address/provinces.json";

import {
  CouponService,
  UserService,
  DepartmentService,
  SegmentService,
  EventService,
  CardService,
  NotificationService,
  QuestionService,
  UploadService,
} from "@/services";

export default {
  name: "card-page",
  data() {
    return {
      selectedData: null,
      editingData: null,
      isShowForm: false,
      isShowBroadcast: false,
      records: [],
      page: 1,
      totalPage: 1,
      search: null,
      ordering: null,
      users: [],
      departments: [],
      segments: [],
      eventPackages: [],
      cards: [],
      coupons: [],
      health: [],
      interest: [],
      loading: null,
      error: null,
      provinces,
    };
  },
  components: {
    Head,
    Table,
    Search,
    Detail,
    Form,
    Broadcast,
  },
  computed: {
    provinceList: function () {
      return Object.keys(this.provinces).map((id) => {
        return {
          id,
          ...this.provinces[id],
        };
      });
    },
  },
  async mounted() {
    this.loading = "load";
    try {
      const data = await CouponService.list();
      this.records = data.results;
      this.page = data.page;
      this.totalPage = data.totalPage;
      await Promise.all([
        UserService.list({
          page_size: 9999,
        }),
        DepartmentService.list({
          page_size: 9999,
        }),
        SegmentService.list({
          page_size: 9999,
        }),
        EventService.list({
          page_size: 9999,
        }),
        CardService.list({
          page_size: 9999,
        }),
        CouponService.list({
          page_size: 9999,
        }),
        QuestionService.listHealth(),
        QuestionService.listInterest(),
      ]).then((resp) => {
        const [
          userData,
          departmentData,
          segmentData,
          eventPackageData,
          cardData,
          couponData,
          healthData,
          interestData,
        ] = resp;
        this.users = userData.results;
        this.departments = departmentData.results;
        this.segments = segmentData.results;
        this.eventPackages = eventPackageData.results;
        this.cards = cardData.results;
        this.coupons = couponData.results.filter(c => c.collect_method === 'exclusive');
        this.health = healthData;
        this.interest = interestData;
      });
      this.loading = "success";
    } catch (e) {
      this.loading = "fail";
      this.error = e.message;
    }
  },
  methods: {
    async fetchCoupon() {
      const data = await CouponService.list({
        page: this.page,
        search: this.search,
        ordering: this.ordering,
      });
      this.records = data.results;
      this.page = data.page;
      this.totalPage = data.totalPage;
      const couponData = await CouponService.list({
        page_size: 9999,
      });
      this.coupons = couponData.results.filter(c => c.collect_method === 'exclusive');
    },
    applySearch(form) {
      this.loading = "load";
      try {
        this.search = form.keyword || null;
        this.page = 1;
        this.fetchCoupon();
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    showDetail(data) {
      this.closeForm();
      this.closeBroadcast();

      let isSwitch = this.selectedData && data != this.selectedData;
      if (isSwitch) {
        this.closeDetail();
        setTimeout(() => {
          this.selectedData = data;
        }, 300);
      } else if (data == this.selectedData) {
        this.closeDetail();
      } else {
        this.selectedData = data;
      }
    },
    closeDetail() {
      this.selectedData = null;
    },
    showForm(data) {
      this.closeDetail();
      this.closeBroadcast();

      if (this.isShowForm) {
        this.closeForm();
        setTimeout(() => {
          this.isShowForm = true;
          this.editingData = data;
        }, 300);
      } else {
        this.isShowForm = true;
        this.editingData = data;
      }
    },
    closeForm() {
      this.isShowForm = false;
      this.editingData = null;
    },
    async deleteCoupon(data) {
      let confirmed = window.confirm(
        `${this.$t("app.confirm-delete")} \n(${this.$t(
          "sidebar.menu.coupon"
        )} ${data.name})`
      );
      if (confirmed) {
        this.loading = "load";
        try {
          await CouponService.delete(data.id);
          this.fetchCoupon();
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }

      this.closeDetail();
    },
    async updateCoupon(data) {
      let confirmed = window.confirm(
        `${this.$t("app.confirm-update")} \n(${this.$t(
          "sidebar.menu.coupon"
        )} ${data.name})`
      );
      if (confirmed) {
        this.loading = "load";
        try {
          if (data.file) {
            const { url } = await UploadService.upload(data.file);
            data.url = url;
            delete data.file;
          }
          if (data.thumbnailFile) {
            const { url } = await UploadService.upload(data.thumbnailFile);
            data.thumbnail_url = url;
            delete data.thumbnailFile;
          }
          await CouponService.update(data.id, data);
          this.fetchCoupon();
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }
      this.closeForm();
    },
    async createCoupon(data) {
      this.loading = "load";
      try {
        if (data.file) {
          const { url } = await UploadService.upload(data.file);
          data.url = url;
          delete data.file;
        }
        if (data.thumbnailFile) {
          const { url } = await UploadService.upload(data.thumbnailFile);
          data.thumbnail_url = url;
          delete data.thumbnailFile;
        }
        await CouponService.create(data);
        this.fetchCoupon();
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
      this.closeForm();
    },
    showBroadcast(data) {
      this.closeDetail();
      this.closeForm();

      if (this.isShowBroadcast) {
        this.closeForm();
        setTimeout(() => {
          this.isShowBroadcast = true;
          this.editingData = data;
        }, 300);
      } else {
        this.isShowBroadcast = true;
        this.editingData = data;
      }
    },
    closeBroadcast() {
      this.isShowBroadcast = false;
      this.editingData = null;
    },
    async broadcastCoupon(data) {
      let confirmed = window.confirm(`${this.$t("app.confirm-send")}`);
      if (confirmed) {
        this.loading = "load";
        try {
          await NotificationService.createMulticast({
            ...data,
            is_require_accept_market: true
          });
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }
      this.closeBroadcast();
    },
  },
};
</script>
