import BaseRepo from '@/repos/BaseRepo';

class LineRepo {
    createSingle(params) {
        return BaseRepo.post('bo/noti_single/', params);
    }

    listSingle(params) {
        return BaseRepo.get('bo/noti_single/', { params });
    }

    deleteSingle(id) {
        return BaseRepo.delete(`bo/noti_single/${id}/`);
    }

    createMulticast(params) {
        return BaseRepo.post('bo/noti_multicast/', params);
    }

    listMulticast(params) {
        return BaseRepo.get('bo/noti_multicast/', { params });
    }

    deleteMulticast(id) {
        return BaseRepo.delete(`bo/noti_multicast/${id}/`);
    }

    createBroadcast(params) {
        return BaseRepo.post('bo/noti_broadcast/', params);
    }

    listBroadcast(params) {
        return BaseRepo.get('bo/noti_broadcast/', { params });
    }

    deleteBroadcast(id) {
        return BaseRepo.delete(`bo/noti_broadcast/${id}/`);
    }
}

export default new LineRepo();