<template>
  <nav
    class="
      md:left-0
      md:block
      md:fixed
      md:top-0
      md:bottom-0
      md:overflow-y-auto
      md:flex-row md:flex-nowrap
      md:overflow-hidden
      shadow-xl
      bg-bg_color_white
      flex flex-wrap
      items-center
      justify-between
      relative
      md:w-64
      z-10
      py-4
      px-6
    "
  >
    <div
      class="
        md:flex-col
        md:items-stretch
        md:min-h-full
        md:flex-nowrap
        px-0
        flex flex-wrap
        items-center
        justify-between
        w-full
        mx-auto
      "
    >
      <!-- Toggler -->
      <button
        class="
          cursor-pointer
          text-text_color_1
          opacity-50
          md:hidden
          px-3
          py-1
          text-xl
          leading-none
          bg-transparent
          rounded
          border border-solid border-transparent
        "
        type="button"
        v-on:click="toggleCollapseShow('bg-bg_color_white m-2 py-3 px-6')"
      >
        <i class="fas fa-bars"></i>
      </button>
      <!-- Brand -->
      <div>
        <router-link
          class="
            md:block
            text-left
            md:pb-2
            text-text_color_1-600
            mr-0
            inline-block
            whitespace-nowrap
            text-sm
            uppercase
            font-bold
            p-4
            px-0
          "
          to="/admin/users"
        >
          <div>{{ $t("app.name") }}</div>
          <div class="text-xs text-text_color_1-300">{{ version }}</div>
        </router-link>
      </div>
      <div class="flex gap-x-4">
        <button
          :class="{
            'text-text_selected_color_1-500': $i18n.locale === 'th',
          }"
          @click="switchLocale('th')"
        >
          TH
        </button>
        <button
          :class="{
            'text-text_selected_color_1-500': $i18n.locale === 'en',
          }"
          @click="switchLocale('en')"
        >
          EN
        </button>
      </div>
      <!-- Collapse -->
      <div
        class="
          md:flex md:flex-col
          md:items-stretch
          md:opacity-100
          md:relative
          md:mt-4
          md:shadow-none
          shadow
          absolute
          top-0
          left-0
          right-0
          z-40
          overflow-y-auto overflow-x-hidden
          h-auto
          items-center
          flex-1
          rounded
        "
        v-bind:class="collapseShow"
      >
        <!-- Collapse header -->
        <div
          class="
            md:min-w-full
            md:hidden
            block
            pb-4
            mb-4
            border-b border-solid
            border-border_color_1-200
          "
        >
          <div class="flex flex-wrap">
            <div class="w-6/12">
              <router-link
                class="
                  md:block
                  text-left
                  md:pb-2
                  text-text_color_1-600
                  mr-0
                  inline-block
                  whitespace-nowrap
                  text-sm
                  uppercase
                  font-bold
                  p-4
                  px-0
                "
                to="/admin/users"
              >
                {{ $t("app.name") }}
              </router-link>
            </div>
            <div class="w-6/12 flex justify-end">
              <button
                type="button"
                class="
                  cursor-pointer
                  text-text_color_1
                  opacity-50
                  md:hidden
                  px-3
                  py-1
                  text-xl
                  leading-none
                  bg-transparent
                  rounded
                  border border-solid border-transparent
                "
                v-on:click="toggleCollapseShow('hidden')"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>

        <!-- Divider -->
        <hr class="hidden md:block my-4 md:min-w-full" />

        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li v-for="(menu, i) in activeMenus" :key="i" class="items-center">
            <router-link :to="menu.link" v-slot="{ href, navigate, isActive }">
              <a
                :href="href"
                @click="navigate"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-text_selected_color_1-500 hover:text-text_selected_color_1-600'
                    : 'text-text_color_1-700 hover:text-text_color_1-500',
                ]"
              >
                <i
                  class="fas mr-2 text-sm"
                  :class="[
                    menu.iconClass,
                    isActive ? 'opacity-75' : 'text-text_color_1-300',
                  ]"
                ></i>
                {{ $t(menu.label) }}
              </a>
            </router-link>
          </li>
        </ul>

        <!-- Divider -->
        <hr class="hidden md:block my-4 md:min-w-full" />

        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li class="items-center">
            <router-link to="/" v-slot="{ href, navigate, isActive }">
              <a
                :href="href"
                @click="navigate"
                class="text-xs uppercase py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-text_selected_color_1-500 hover:text-text_selected_color_1-600'
                    : 'text-text_color_1-700 hover:text-text_color_1-500',
                ]"
              >
                <i class="fas fa-sign-out-alt mr-2 text-sm"></i>
                {{ $t("sidebar.menu.logout") }}
              </a>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
); }

<script>
import { version } from "@/configs/api.config.js";

export default {
  data() {
    return {
      locales: ["th", "en"],
      lang: this.$i18n.locale,
      version,
      collapseShow: "hidden",
      menus: [
        {
          link: "/admin/dashboard",
          label: "sidebar.menu.dashboard",
          iconClass: "fa-tv",
          active: true,
        },
        {
          link: "/admin/users",
          label: "sidebar.menu.user",
          iconClass: "fa-users",
          active: true,
        },
        {
          link: "/admin/segments",
          label: "sidebar.menu.segment",
          iconClass: "fa-object-group",
          active: true,
        },
        {
          link: "/admin/coupons",
          label: "sidebar.menu.coupon",
          iconClass: "fa-ticket-alt",
          active: true,
        },
        {
          link: "/admin/coupon-usages",
          label: "sidebar.menu.coupon_usages",
          iconClass: "fa-ticket-alt",
          active: true,
        },
        {
          link: "/admin/cards",
          label: "sidebar.menu.card",
          iconClass: "fa-id-card",
          active: true,
        },
        {
          link: "/admin/events",
          label: "sidebar.menu.event",
          iconClass: "fa-calendar-check",
          active: true,
        },
        {
          link: "/admin/departments",
          label: "sidebar.menu.department",
          iconClass: "fa-object-group",
          active: true,
        },
        {
          link: "/admin/staffs",
          label: "sidebar.menu.staff",
          iconClass: "fa-user-tie",
          active: true,
        },
        {
          link: "/admin/acc-purchase",
          label: "sidebar.menu.acc_purchase",
          iconClass: "fa-money-bill-alt",
          active: true,
        },
        {
          link: "/admin/line",
          label: "sidebar.menu.line",
          iconClass: "fa-mobile-alt",
          active: true,
        },
        {
          link: "/admin/line-history",
          label: "sidebar.menu.line-history",
          iconClass: "fa-mobile-alt",
          active: true,
        },
        {
          link: "/admin/reports",
          label: "sidebar.menu.report",
          iconClass: "fa-folder-open",
          active: true,
        },
      ],
    };
  },
  computed: {
    activeMenus() {
      return this.menus.filter((m) => m.active);
    },
  },
  methods: {
    toggleCollapseShow: function (classes) {
      this.collapseShow = classes;
    },
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
      }
    },
  },
};
</script>
