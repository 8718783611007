<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-bg_color_1-100
      border-0
    "
  >
    <div class="flex items-center px-4 py-4 bg-bg_color_white">
      <h3 class="font-semibold text-lg text-text_color_1-700">
        {{ $t("line.broadcast.title") }}
      </h3>
    </div>
    <div class="flex flex-col items-center">
      <div class="flex flex-wrap justify-start w-full">
        <div class="w-full px-4">
          <div class="w-full items-center my-4">
            <label
              class="
                block
                uppercase
                text-text_color_1-600
                text-xs
                font-bold
                mb-2
              "
              >{{ $t("line.broadcast.message") }}
            </label>
            <textarea
              v-model="form.message"
              type="text"
              min="0"
              max="100"
              class="
                border-0
                border-border_color_1-500
                px-4
                py-1
                placeholder-placeholder_color_1-300
                text-text_color_1-600
                bg-bg_color_white
                rounded
                text-sm
                shadow
                focus:outline-none
                focus:ring
                ease-linear
                transition-all
                duration-150
                ml-2
                w-full
              "
            />
          </div>
        </div>

        <div class="w-full px-4">
          <div class="w-full items-center my-4">
            <label
              class="
                block
                uppercase
                text-text_color_1-600
                text-xs
                font-bold
                mb-2
              "
              >{{ $t("line.broadcast.sent_at") }}
            </label>
            <div class="flex items-center gap-x-4">
              <datepicker
                v-model="date"
                :lowerLimit="today"
                clearable
                class="
                  border-0
                  px-3
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  ease-linear
                  transition-all
                  duration-150
                "
              >
                <template v-slot:clear="{ onClear }">
                  <button @click="onClear">x</button>
                </template>
              </datepicker>
              <label
                class="block uppercase text-text_color_1-600 text-xs font-bold"
                >{{ $t("line.broadcast.hour") }}
              </label>
              <div class="flex gap-x-2">
                <select
                  v-model="hour"
                  class="
                    border-0
                    placeholder-placeholder_color_1-300
                    text-text_color_1-600
                    bg-bg_color_white
                    rounded
                    text-sm
                    shadow
                    focus:outline-none
                    focus:ring
                    ease-linear
                    transition-all
                    duration-150
                    mr-2
                  "
                >
                  <option
                    v-for="(hour, i) in validHours"
                    :key="i"
                    :value="hour.value"
                  >
                    {{ hour.label }}
                  </option>
                </select>
                <label
                  class="
                    block
                    uppercase
                    text-text_color_1-600
                    text-xs
                    font-bold
                  "
                  >{{ $t("line.broadcast.minute") }}
                </label>
                <select
                  v-model="min"
                  class="
                    border-0
                    placeholder-placeholder_color_1-300
                    text-text_color_1-600
                    bg-bg_color_white
                    rounded
                    text-sm
                    shadow
                    focus:outline-none
                    focus:ring
                    ease-linear
                    transition-all
                    duration-150
                  "
                >
                  <option
                    v-for="(min, i) in validMins"
                    :key="i"
                    :value="min.value"
                  >
                    {{ min.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap px-4 py-4">
          <button
            class="
              bg-btn_green_color_active-500
              text-text_color_white
              active:bg-btn_green_color_active-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            @click="save"
          >
            <i class="fas fa-paper-plane"></i> {{ $t("app.button.broadcast") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
); }

<script>
import Datepicker from "vue3-datepicker";

export default {
  data() {
    return {
      form: {},
      date: null,
      hour: null,
      min: null,
      maxLevel: 1,
    };
  },
  components: {
    Datepicker,
  },
  emits: ["onSubmit"],
  methods: {
    save() {
      this.$emit("onSubmit", {
        ...this.form,
        is_send_now: !this.resolveTime(),
        send_at: this.resolveTime(),
      });
    },
    resolveTime() {
      if (this.date === null || this.hour === null || this.min === null)
        return null;
      const datetime = this.$moment(this.date)
        .set("hour", this.hour)
        .set("minute", this.min)
        .set("second", 0)
        .format();
      return datetime;
    },
  },
  watch: {
    date(newVal, oldVal) {
      if (newVal != oldVal) {
        this.hour = null;
        this.min = null;
      }
    },
  },
  computed: {
    today() {
      return new Date();
    },
    validHours() {
      let d = new Date();
      let h = 0;
      let currentH = d.getHours();
      if (this.date && this.$moment(this.date).isSame(d, "day")) {
        h = currentH;
      }
      let hours = [{ value: null, label: "--" }];
      for (let i = h; i < 24; i++) {
        let label = (i + "").padStart(2, "0");
        hours.push({ value: i, label });
      }

      return hours;
    },
    validMins() {
      let d = new Date();
      let h = d.getHours();
      let hLabel = (h + "").padStart(2, "0");
      let m = 0;
      if (
        this.date &&
        this.$moment(this.date).isSame(d, "day") &&
        this.hour &&
        this.hour == hLabel
      ) {
        m = d.getMinutes();
      }
      if (m % 5 != 0) {
        m += 5 - (m % 5);
      }
      let mins = [{ value: null, label: "--" }];
      for (let i = m; i < 60; i += 5) {
        let label = (i + "").padStart(2, "0");
        mins.push({ value: i, label });
      }

      return mins;
    },
  },
};
</script>

<style scoped>
</style>