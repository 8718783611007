import { ReportRepo } from "@/repos";

class ReportService {
    async downloadUser(params) {
        const { data, headers } = await ReportRepo.downloadUser(params);
        return { data, contentType: headers["content-type"] || "application/ms-excel" };
    }

    async downloadUsageHistory(params) {
        const { data, headers } = await ReportRepo.downloadUsageHistory(params);
        return { data, contentType: headers["content-type"] || "application/ms-excel" };
    }

    async downloadCard(params) {
        const { data, headers } = await ReportRepo.downloadCard(params);
        return { data, contentType: headers["content-type"] || "application/ms-excel" };
    }

    async downloadCoupon(params) {
        const { data, headers } = await ReportRepo.downloadCoupon(params);
        return { data, contentType: headers["content-type"] || "application/ms-excel" };
    }

    async downloadCouponBroadcast(params) {
        const { data, headers } = await ReportRepo.downloadCouponBroadcast(params);
        return { data, contentType: headers["content-type"] || "application/ms-excel" };
    }

    async downloadSummary(params) {
        const { data, headers } = await ReportRepo.downloadSummary(params);
        return { data, contentType: headers["content-type"] || "application/ms-excel" };
    }
}

export default new ReportService();