<template>
  <div class="container px-4 min-h-screen">
    <loader
      :model="loading"
      :detail="error"
      @close="
        loading = null;
        error = null;
      "
    />
    <Head />
    <div class="flex flex-wrap">
      <Broadcast
        @onSubmit="sendBroadcast"
        :users="users"
        :segments="segments"
        :eventPackages="eventPackages"
        :cards="cards"
        :health="health"
        :interest="interest"
      />
      <Multicast
        @onSubmit="sendMulticast"
        :users="users"
        :segments="segments"
        :eventPackages="eventPackages"
        :cards="cards"
        :health="health"
        :interest="interest"
        :provinces="provinceList"
      />
    </div>
  </div>
</template>
<script>
import Head from "@/components/Line/Head.vue";
import Broadcast from "@/components/Line/Broadcast.vue";
import Multicast from "@/components/Line/Multicast.vue";
import provinces from "@/assets/address/provinces.json";

import {
  UserService,
  DepartmentService,
  SegmentService,
  EventService,
  CardService,
  NotificationService,
  QuestionService,
} from "@/services";

export default {
  name: "line-page",
  data() {
    return {
      form: null,
      users: [],
      departments: [],
      segments: [],
      eventPackages: [],
      cards: [],
      health: {},
      interest: {},
      loading: null,
      error: null,
      provinces,
    };
  },
  components: {
    Head,
    Broadcast,
    Multicast,
  },
  async mounted() {
    this.loading = "load";
    try {
      await Promise.all([
        UserService.list({
          page_size: 9999,
        }),
        DepartmentService.list({
          page_size: 9999,
        }),
        SegmentService.list({
          page_size: 9999,
        }),
        EventService.list({
          page_size: 9999,
        }),
        CardService.list({
          page_size: 9999,
        }),
        QuestionService.listHealth(),
        QuestionService.listInterest(),
      ]).then((resp) => {
        const [
          userData,
          departmentData,
          segmentData,
          eventPackageData,
          cardData,
          healthData,
          interestData,
        ] = resp;
        this.users = userData.results;
        this.departments = departmentData.results;
        this.segments = segmentData.results;
        this.eventPackages = eventPackageData.results;
        this.cards = cardData.results;
        this.health = healthData;
        this.interest = interestData;
      });
      this.loading = "success";
    } catch (e) {
      this.loading = "fail";
      this.error = e.message;
    }
  },
  computed: {
    provinceList: function () {
      return Object.keys(this.provinces).map((id) => {
        return {
          id,
          ...this.provinces[id],
        };
      });
    },
  },
  methods: {
    async sendMulticast(data) {
      let confirmed = window.confirm(
        `${this.$t("app.confirm-send")} \n(${this.$t("sidebar.menu.line")} ${
          data.message
        })`
      );
      if (confirmed) {
        this.loading = "load";
        try {
          await NotificationService.createMulticast({
            ...data,
            is_require_accept_market: true
          });
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }
    },
    async sendBroadcast(data) {
      let confirmed = window.confirm(
        `${this.$t("app.confirm-send")} \n(${this.$t("sidebar.menu.line")} ${
          data.name
        })`
      );
      if (confirmed) {
        this.loading = "load";
        try {
          await NotificationService.createBroadcast({
            ...data,
            is_require_accept_market: true
          });
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }
    },
  },
};
</script>
