import BaseRepo from '@/repos/BaseRepo';

const API_PATH = "/bo/report/excel";

class ReportRepo {
    downloadUser(params) {
        return BaseRepo.get(`${API_PATH}/user`, {  responseType: 'blob', params });
    }
    
    downloadUsageHistory(params) {
        return BaseRepo.get(`${API_PATH}/usage_history`, { responseType: 'blob', params });
    }

    downloadCard(params) {
        return BaseRepo.get(`${API_PATH}/cards`, { responseType: 'blob', params });
    }
    
    downloadCoupon(params) {
        return BaseRepo.get(`${API_PATH}/coupons`, { responseType: 'blob', params });
    }

    downloadCouponBroadcast(params) {
        return BaseRepo.get(`${API_PATH}/coupon_broadcasts`, { responseType: 'blob', params });
    }
    
    downloadSummary(params) {
        return BaseRepo.get(`${API_PATH}/summary`, { responseType: 'blob', params });
    }
}

export default new ReportRepo();