<template>
  <div class="container px-4 min-h-screen">
    <loader
      :model="loading"
      :detail="error"
      @close="
        loading = null;
        error = null;
      "
    />
    <Head />
    <Detail
      :model="selectedData"
      @edit="showForm"
      @delete="deleteDepartment"
      @close="closeDetail"
    />
    <Form
      :active="isShowForm"
      :model="editingData"
      @close="closeForm"
      @onSubmitUpdate="updateDepartment"
      @onSubmitCreate="createDepartment"
    />
    <Staffs
      :active="isShowStaffs"
      :model="editingData"
      :staffs="staffs"
      @close="closeStaffs"
      @onSubmit="updateStaffs"
    />
    <div class="flex flex-wrap">
      <Search @onApply="applySearch" />
    </div>
    <div class="flex flex-wrap">
      <Table
        name="Department"
        :records="records"
        :page="page"
        :totalPage="totalPage"
        @onRecClick="showDetail"
        @onStaffClick="showStaffs"
        @onAddClick="() => showForm(null)"
        @onPageChange="
          (toPage) => {
            page = toPage;
            fetchDepartment();
          }
        "
        @onSortChange="
          (query) => {
            ordering = query;
            fetchDepartment();
          }
        "
      />
    </div>
  </div>
</template>
<script>
import Head from "@/components/Departments/Head.vue";
import Table from "@/components/Departments/Table.vue";
import Search from "@/components/Departments/Search.vue";
import Detail from "@/components/Departments/Detail.vue";
import Form from "@/components/Departments/Form.vue";
import Staffs from "@/components/Departments/Staffs.vue";

import { DepartmentService, StaffService } from "@/services";

export default {
  name: "department-page",
  data() {
    return {
      selectedData: null,
      editingData: null,
      isShowForm: false,
      isShowStaffs: false,
      staffs: [],
      records: [],
      page: 1,
      totalPage: 1,
      search: null,
      ordering: null,
      loading: null,
      error: null,
    };
  },
  components: {
    Head,
    Table,
    Search,
    Detail,
    Form,
    Staffs,
  },
  async mounted() {
    this.loading = "load";
    try {
      const data = await DepartmentService.list();
      this.records = data.results;
      this.page = data.page;
      this.totalPage = data.totalPage;
      const staffData = await StaffService.list({
        page_size: 9999,
      });
      this.staffs = staffData.results;
      this.loading = "success";
    } catch (e) {
      this.loading = "fail";
      this.error = e.message;
    }
  },
  methods: {
    async fetchDepartment() {
      const data = await DepartmentService.list({
        page: this.page,
        search: this.search,
        ordering: this.ordering,
      });
      this.records = data.results;
      this.page = data.page;
      this.totalPage = data.totalPage;
    },
    applySearch(form) {
      this.loading = "load";
      try {
        this.search = form.keyword || null;
        this.page = 1;
        this.fetchDepartment();
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    showDetail(data) {
      this.closeForm();
      this.closeStaffs();

      let isSwitch = this.selectedData && data != this.selectedData;
      if (isSwitch) {
        this.closeDetail();
        setTimeout(() => {
          this.selectedData = data;
        }, 300);
      } else if (data == this.selectedData) {
        this.closeDetail();
      } else {
        this.selectedData = data;
      }
    },
    closeDetail() {
      this.selectedData = null;
    },
    showForm(data) {
      this.closeDetail();
      this.closeStaffs();
      if (this.isShowForm) {
        this.closeForm();
        setTimeout(() => {
          this.isShowForm = true;
          this.editingData = data;
        }, 300);
      } else {
        this.isShowForm = true;
        this.editingData = data;
      }
    },
    closeForm() {
      this.isShowForm = false;
      this.editingData = null;
    },
    async deleteDepartment(data) {
      let confirmed = window.confirm(
        `${this.$t("app.confirm-delete")} \n(${this.$t(
          "sidebar.menu.department"
        )} ${data.name})`
      );
      if (confirmed) {
        this.loading = "load";
        try {
          await DepartmentService.delete(data.id);
          this.fetchDepartment();
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }

      this.closeDetail();
    },
    async updateDepartment(data) {
      let confirmed = window.confirm(
        `${this.$t("app.confirm-update")} \n(${this.$t(
          "sidebar.menu.department"
        )} ${data.name})`
      );
      if (confirmed) {
        this.loading = "load";
        try {
          await DepartmentService.update(data.id, data);
          this.fetchDepartment();
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }
      this.closeForm();
    },
    async createDepartment(data) {
      this.loading = "load";
      try {
        await DepartmentService.create(data);
        this.fetchDepartment();
        this.closeForm();
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    showStaffs(data) {
      this.closeDetail();
      this.closeForm();
      if (this.isShowStaffs) {
        this.closeStaffs();
        setTimeout(() => {
          this.isShowStaffs = true;
          this.editingData = data;
        }, 300);
      } else {
        this.isShowStaffs = true;
        this.editingData = data;
      }
    },
    closeStaffs() {
      this.isShowStaffs = false;
      this.editingData = null;
    },
    async updateStaffs(data) {
      let confirmed = window.confirm(`${this.$t("app.confirm-update")}`);
      if (confirmed) {
        this.loading = "load";
        try {
          await DepartmentService.setStaffs(this.editingData.id, data);
          this.fetchDepartment();
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }
      this.closeStaffs();
    },
  },
};
</script>
