<template>
  <div class="container px-4 min-h-screen">
    <loader
      :model="loading"
      :detail="error"
      @close="
        loading = null;
        error = null;
      "
    />
    <Head />
    <Detail
      :model="selectedData"
      @edit="showForm"
      @delete="deleteEvent"
      @close="closeDetail"
    />
    <Form
      :active="isShowForm"
      :model="editingData"
      @close="closeForm"
      @onSubmitUpdate="updateEvent"
      @onSubmitCreate="createEvent"
    />
    <Card
      :active="isShowCard"
      @close="closeCard"
      :model="currentCard"
      :records="cards"
      @onSubmit="updateCards"
    />
    <div class="flex flex-wrap">
      <Search @onApply="applySearch" />
    </div>
    <div class="flex flex-wrap">
      <Table
        name="Events"
        :records="records"
        :page="page"
        :totalPage="totalPage"
        @onRecClick="showDetail"
        @onAddClick="() => showForm(null)"
        @onPageChange="
          (toPage) => {
            page = toPage;
            fetchEvent();
          }
        "
        @onSortChange="
          (query) => {
            ordering = query;
            fetchEvent();
          }
        "
        @onCardClick="showCard"
      />
    </div>
  </div>
</template>
<script>
import Head from "@/components/Events/Head.vue";
import Table from "@/components/Events/Table.vue";
import Search from "@/components/Events/Search.vue";
import Detail from "@/components/Events/Detail.vue";
import Form from "@/components/Events/Form.vue";
import Card from "@/components/Events/Card.vue";

import { EventService, CardService } from "@/services";

export default {
  name: "event-page",
  data() {
    return {
      selectedData: null,
      editingData: null,
      isShowForm: false,
      isShowCard: false,
      records: [],
      page: 1,
      totalPage: 1,
      search: null,
      ordering: null,
      cards: [],
      currentCard: [],
      loading: null,
      error: null,
    };
  },
  components: {
    Head,
    Table,
    Search,
    Detail,
    Form,
    Card,
  },
  async mounted() {
    this.loading = "load";
    try {
      await this.fetchEvent();
      await this.fetchCard();
      this.loading = "success";
    } catch (e) {
      this.loading = "fail";
      this.error = e.message;
    }
  },
  methods: {
    async fetchEvent() {
      const data = await EventService.list({
        page: this.page,
        search: this.search,
        ordering: this.ordering,
      });
      this.records = data.results;
      this.page = data.page;
      this.totalPage = data.totalPage;
    },
    async fetchCard() {
      const data = await CardService.list({
        page_size: 9999,
      });
      this.cards = data.results;
    },
    applySearch(form) {
      this.loading = "load";
      try {
        this.search = form.keyword || null;
        this.page = 1;
        this.fetchEvent();
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    showDetail(event) {
      this.closeForm();

      let isSwitch = this.selectedData && event != this.selectedData;
      if (isSwitch) {
        this.closeDetail();
        setTimeout(() => {
          this.selectedData = event;
        }, 300);
      } else if (event == this.selectedData) {
        this.closeDetail();
      } else {
        this.selectedData = event;
      }
    },
    closeDetail() {
      this.selectedData = null;
    },
    showForm(event) {
      this.closeDetail();

      if (this.isShowForm) {
        this.closeForm();
        setTimeout(() => {
          this.isShowForm = true;
          this.editingData = event;
        }, 300);
      } else {
        this.isShowForm = true;
        this.editingData = event;
      }
    },
    closeForm() {
      this.isShowForm = false;
      this.editingData = null;
    },
    showCard(event) {
      this.closeDetail();
      this.closeForm();

      if (this.isShowCard) {
        this.closeCard();
        setTimeout(() => {
          this.isShowCard = true;
          this.editingData = event;
          if (event) {
            this.currentCard = event.member_cards;
          }
        }, 300);
      } else {
        this.isShowCard = true;
        this.editingData = event;
        if (event) {
          this.currentCard = event.member_cards;
        }
      }
    },
    closeCard() {
      this.isShowCard = false;
    },
    async deleteEvent(data) {
      let confirmed = window.confirm(
        `${this.$t("app.confirm-delete")} \n(${this.$t("sidebar.menu.event")} ${
          data.code
        })`
      );
      if (confirmed) {
        this.loading = "load";
        try {
          await EventService.delete(data.id);
          this.fetchEvent();
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }

      this.closeDetail();
    },
    async updateEvent(data) {
      let confirmed = window.confirm(
        `${this.$t("app.confirm-update")} \n(${this.$t("sidebar.menu.event")} ${
          data.code
        })`
      );
      if (confirmed) {
        this.loading = "load";
        try {
          await EventService.update(data.id, data);
          this.fetchEvent();
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }
      this.closeForm();
    },
    async createEvent(data) {
      this.loading = "load";
      try {
        await EventService.create(data);
        this.fetchEvent();
        this.closeForm();
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    async updateCards(cardIds) {
      this.loading = "load";
      try {
        await EventService.setCards(this.editingData.id, cardIds);
        this.closeCard();
        this.fetchEvent();
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
  },
};
</script>
