import { NotificationRepo } from "@/repos";
import { pageSize } from '@/configs/api.config'

const perPage = pageSize || 10;

class NotificationService {
    async createSingle(params) {
        params['is_active'] = true;
        let { data } = await NotificationRepo.createSingle(params);
        return data;
    }

    async listSingle(params) {
        params = params || {};
        params['page_size'] = params['page_size'] || perPage;

        let { data } = await NotificationRepo.listSingle(params);
        data.page = params.page || 1;
        data.totalPage = Math.ceil(data.count / params['page_size']);

        return data;
    }

    async deleteSingle(id) {
        let { data } = await NotificationRepo.deleteSingle(id);
        return data;
    }

    async createMulticast(params) {
        params['is_active'] = true;
        let { data } = await NotificationRepo.createMulticast(params);
        return data;
    }

    async listMulticast(params) {
        params = params || {};
        params['page_size'] = params['page_size'] || perPage;

        let { data } = await NotificationRepo.listMulticast(params);
        data.page = params.page || 1;
        data.totalPage = Math.ceil(data.count / params['page_size']);

        return data;
    }

    async deleteMulticast(id) {
        let { data } = await NotificationRepo.deleteMulticast(id);
        return data;
    }

    async createBroadcast(params) {
        params['is_active'] = true;
        let { data } = await NotificationRepo.createBroadcast(params);
        return data;
    }

    async listBroadcast(params) {
        params = params || {};
        params['page_size'] = params['page_size'] || perPage;

        let { data } = await NotificationRepo.listBroadcast(params);
        data.page = params.page || 1;
        data.totalPage = Math.ceil(data.count / params['page_size']);

        return data;
    }

    async deleteBroadcast(id) {
        let { data } = await NotificationRepo.deleteBroadcast(id);
        return data;
    }
}

export default new NotificationService();