<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-bg_color_1-100
      border-0
    "
  >
    <div class="flex items-center px-4 py-4 bg-bg_color_white">
      <h3 class="font-semibold text-lg text-text_color_1-700">
        {{ $t("report.user.title") }}
      </h3>
    </div>
    <div class="flex flex-col items-center">
      <div class="flex flex-wrap justify-start w-full">
        <!-- <div class="flex gap-x-4 w-full px-4">
          <div class="w-1/4 items-center my-4">
            <label
              class="
                block
                uppercase
                text-text_color_1-600
                text-xs
                font-bold
                mb-2
              "
              >{{ $t("report.start") }}
            </label>
            <div class="flex items-center gap-x-4">
              <datepicker
                v-model="date"
                :lowerLimit="today"
                clearable
                class="
                  border-0
                  px-3
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  ease-linear
                  transition-all
                  duration-150
                "
              >
                <template v-slot:clear="{ onClear }">
                  <button @click="onClear">x</button>
                </template>
              </datepicker>
            </div>
          </div>
          <div class="w-1/4 items-center my-4">
            <label
              class="
                block
                uppercase
                text-text_color_1-600
                text-xs
                font-bold
                mb-2
              "
              >{{ $t("report.end") }}
            </label>
            <div class="flex items-center gap-x-4">
              <datepicker
                v-model="date"
                :lowerLimit="today"
                clearable
                class="
                  border-0
                  px-3
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  ease-linear
                  transition-all
                  duration-150
                "
              >
                <template v-slot:clear="{ onClear }">
                  <button @click="onClear">x</button>
                </template>
              </datepicker>
            </div>
          </div>
        </div> -->

        <div class="flex flex-wrap px-4 py-4">
          <button
            class="
              bg-btn_green_color_active-500
              text-text_color_white
              active:bg-btn_green_color_active-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            @click="download"
          >
            <i class="fas fa-paper-plane"></i> {{ $t("app.button.download") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
); }

<script>
export default {
  emits: ["onDownload"],
  methods: {
    download() {
      this.$emit("onDownload")
    }
  }
};
</script>

<style scoped>
</style>