<template>
  <nav
    class="right-nav fixed top-0 bottom-0 overflow-y-auto overflow-hidden shadow-xl bg-bg_color_1-100 w-64 z-65 py-4 px-6 h-full"
    :class="active ? 'active' : 'inactive'"
  >
    <div class="flex justify-start">
      <a
        class="text-text_color_1-400 block cursor-pointer px-4 py-4 border border-border_color_1-400 rounded-lg"
        @click="$emit('close')"
      >
        <i class="fas fa-angle-double-right"></i>
      </a>
    </div>
    <div v-if="active" class="flex flex-col items-center">
      <form class="w-full" @submit.prevent="save" enctype="multipart/form-data">
        <h6 class="text-text_color_1-400 text-sm mt-3 mb-3 font-bold uppercase">
          {{ $t("users.import.label-section1") }}
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full px-4 pt-2">
            <div class="relative w-full">
              <label
                class="block uppercase text-text_color_1-600 text-xs font-bold mb-2"
              >
                {{ $t("users.import.file") }}
              </label>
              <input
                id="file"
                ref="file"
                type="file"
                @change="handleFileUpload"
                class="border-0 px-3 py-1 placeholder-placeholder_color_1-300 text-text_color_1-600 bg-bg_color_white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
        </div>

        <div class="flex flex-wrap mt-10">
          <button
            class="bg-btn_green_color_active-500 text-text_color_white active:bg-btn_green_color_active-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="submit"
          >
            <i class="fas fa-save"></i> {{ $t("app.button.upload") }}
          </button>
        </div>
      </form>
    </div>
  </nav>
</template>
); }

<script>
export default {
  data() {
    return {
      file: "",
    };
  },
  emits: ['onSubmitImport', 'close'],
  props: {
    active: {
      default: false,
    },
  },
  methods: {
    save() {
      this.$emit("onSubmitImport", this.file);
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
  },
};
</script>

<style scoped>
.right-nav {
  left: auto;
  right: 0;
  width: 50%;
  max-width: 50%;
}

@media (max-width: 768px) {
  .right-nav {
    left: auto;
    right: 0;
    width: 100%;
    max-width: 100%;
  }
}

.active {
  transform: translatex(0);
  transition: all 0.2s ease-out;
}

.inactive {
  transform: translatex(100%);
  transition: all 0.2s ease-out;
}
</style>