<template>
  <nav
    class="
      right-nav
      fixed
      top-0
      bottom-0
      overflow-y-auto overflow-hidden
      shadow-xl
      bg-bg_color_1-100
      w-64
      z-65
      py-4
      px-6
      h-full
    "
    :class="active ? 'active' : 'inactive'"
  >
    <div class="flex justify-start">
      <a
        class="
          text-text_color_1-400
          block
          cursor-pointer
          px-4
          py-4
          border
          border-border_color_1-400
          rounded-lg
        "
        @click="$emit('close')"
      >
        <i class="fas fa-angle-double-right"></i>
      </a>
    </div>
    <div v-if="active" class="flex flex-col items-center">
      <form @submit.prevent="save" class="w-full">
        <h6 class="text-text_color_1-400 text-sm mt-3 mb-3 font-bold uppercase">
          {{ $t("departments.form.label-section1") }}
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full px-4 pt-2">
            <div class="relative w-full">
              <label
                class="
                  block
                  uppercase
                  text-text_color_1-600
                  text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("departments.form.staffs") }}
              </label>
              <multiselect
                class="
                  border-0
                  px-3
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
                v-model="form"
                mode="multiple"
                :delay="3"
                loading
                searchable
                :hideSelected="false"
                :options="staffOpts"
              />
            </div>
          </div>
        </div>

        <div class="flex flex-wrap mt-20">
          <button
            class="
              bg-btn_green_color_active-500
              text-text_color_white
              active:bg-btn_green_color_active-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            type="submit"
          >
            <i class="fas fa-save"></i> {{ $t("app.button.save") }}
          </button>
          <button
            v-if="model"
            class="
              bg-btn_gray_color_active-50
              text-text_color_1-600
              active:bg-btn_gray_color_active-200
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            @click.prevent="reset"
          >
            <i class="fas fa-undo-alt"></i> {{ $t("app.button.reset") }}
          </button>
        </div>
      </form>
    </div>
  </nav>
</template>
); }

<script>
export default {
  data() {
    return {
      form: [],
      staffOpts: [],
    };
  },
  emits: ["close", "onSubmitUpdate", "onSubmitCreate", "onUserClick"],
  props: {
    active: {
      default: false,
    },
    model: {
      default: null,
    },
    staffs: {
      default: [],
    },
  },
  methods: {
    save() {
      this.$emit("onSubmit", [...this.form]);
      this.form = [];
      this.$emit("close");
    },
    reset() {
      let confirmed = window.confirm(`${this.$t("app.confirm-reset")}`);
      if (confirmed) {
        if(this.model) {
          this.form = [...this.model.admin_ids];
        } else {
          this.form = [];
        }
      }
    }
  },
  watch: {
    model(val) {
      if(val) {
        this.form = [...val.admin_ids]
      } else {
        this.form = []
      }
    },
    staffs(val) {
      this.staffOpts = val.filter(u => u && !!u.id)
      .map((u) => {
        return {
          value: u.id,
          label: `${u.first_name || "-"} ${u.last_name || "-"} (${u.username || "-"})`,
        };
      });
    },
  },
};
</script>

<style scoped>
.right-nav {
  left: auto;
  right: 0;
  width: 50%;
  max-width: 50%;
}

@media (max-width: 768px) {
  .right-nav {
    left: auto;
    right: 0;
    width: 100%;
    max-width: 100%;
  }
}

.active {
  transform: translatex(0);
  transition: all 0.2s ease-out;
}

.inactive {
  transform: translatex(100%);
  transition: all 0.2s ease-out;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>