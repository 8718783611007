<template>
  <div class="container px-4 min-h-screen">
    <loader
      :model="loading"
      :detail="error"
      @close="
        loading = null;
        error = null;
      "
    />
    <Head />
    <Detail
      :model="selectedData"
      @edit="showForm"
      @delete="deleteCard"
      @close="closeDetail"
      @onCardClick="handleCardClick"
    />
    <Form
      :active="isShowForm"
      :model="editingData"
      @close="closeForm"
      @onSubmitUpdate="updateCard"
      @onSubmitCreate="createCard"
    />
    <Coupon
      :active="isShowCoupon"
      @close="closeCoupon"
      :records="coupons"
      :model="currentCoupon"
      @onSubmit="updateCoupons"
    />
    <div class="flex flex-wrap">
      <Search @onApply="applySearch" />
    </div>
    <div class="flex flex-wrap">
      <Table
        name="Cards"
        :records="records"
        :page="page"
        :totalPage="totalPage"
        @onRecClick="showDetail"
        @onAddClick="() => showForm(null)"
        @onPageChange="
          (toPage) => {
            page = toPage;
            fetchCard();
          }
        "
        @onSortChange="
          (query) => {
            ordering = query;
            fetchCard();
          }
        "
        @onCouponClick="showCoupon"
      />
    </div>
  </div>
</template>
<script>
import Head from "@/components/Cards/Head.vue";
import Table from "@/components/Cards/Table.vue";
import Search from "@/components/Cards/Search.vue";
import Detail from "@/components/Cards/Detail.vue";
import Form from "@/components/Cards/Form.vue";
import Coupon from "@/components/Cards/Coupon.vue";

import { CardService, CouponService, UploadService } from "@/services";

export default {
  name: "card-page",
  data() {
    return {
      selectedData: null,
      editingData: null,
      isShowForm: false,
      isShowCoupon: false,
      records: [],
      page: 1,
      totalPage: 1,
      search: null,
      ordering: null,
      coupons: [],
      currentCoupon: {},
      loading: null,
      error: null,
    };
  },
  components: {
    Head,
    Table,
    Search,
    Detail,
    Form,
    Coupon,
  },
  async mounted() {
    this.loading = "load";
    try {
      await this.fetchCard();
      await this.fetchCoupon();
      this.loading = "success";
    } catch (e) {
      this.loading = "fail";
      this.error = e.message;
    }
  },
  methods: {
    async fetchCard() {
      const data = await CardService.list({
        page: this.page,
        search: this.search,
        ordering: this.ordering,
      });
      this.records = data.results;
      this.page = data.page;
      this.totalPage = data.totalPage;
    },
    async fetchCoupon() {
      const data = await CouponService.list({
        page_size: 9999,
      });
      this.coupons = data.results;
    },
    applySearch(form) {
      this.loading = "load";
      try {
        this.search = form.keyword || null;
        this.page = 1;
        this.fetchCard();
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    showDetail(card) {
      this.closeForm();
      this.closeCoupon();

      let isSwitch = this.selectedData && card != this.selectedData;
      if (isSwitch) {
        this.closeDetail();
        setTimeout(() => {
          this.selectedData = card;
        }, 300);
      } else if (card == this.selectedData) {
        this.closeDetail();
      } else {
        this.selectedData = card;
      }
    },
    closeDetail() {
      this.selectedData = null;
    },
    showForm(card) {
      this.closeDetail();
      this.closeCoupon();

      if (this.isShowForm) {
        this.closeForm();
        setTimeout(() => {
          this.isShowForm = true;
          this.editingData = card;
        }, 300);
      } else {
        this.isShowForm = true;
        this.editingData = card;
      }
    },
    closeForm() {
      this.isShowForm = false;
      this.editingData = null;
    },
    showCoupon(card) {
      this.closeDetail();
      this.closeForm();

      if (this.isShowCoupon) {
        this.closeCoupon();
        setTimeout(() => {
          this.isShowCoupon = true;
          this.editingData = card;
          if (card) {
            this.currentCoupon = card.coupons.reduce((map, coupon) => {
              map[coupon.id] = true;
              return map;
            }, {});
          }
        }, 300);
      } else {
        this.isShowCoupon = true;
        this.editingData = card;
        if (card) {
          this.currentCoupon = card.coupons.reduce((map, coupon) => {
            map[coupon.id] = true;
            return map;
          }, {});
        }
      }
    },
    closeCoupon() {
      this.isShowCoupon = false;
    },
    async deleteCard(data) {
      let confirmed = window.confirm(
        `${this.$t("app.confirm-delete")} \n(${this.$t("sidebar.menu.card")} ${
          data.name
        })`
      );
      if (confirmed) {
        this.loading = "load";
        try {
          await CardService.delete(data.id);
          this.fetchCard();
          this.fetchCoupon();
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }

      this.closeDetail();
    },
    async updateCard(data) {
      let confirmed = window.confirm(
        `${this.$t("app.confirm-update")} \n(${this.$t("sidebar.menu.card")} ${
          data.name
        })`
      );
      if (confirmed) {
        this.loading = "load";
        try {
          if (data.file) {
            const { url } = await UploadService.upload(data.file);
            data.url = url;
            delete data.file;
          }
          await CardService.update(data.id, data);
          this.fetchCard();
          this.fetchCoupon();
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }
      this.closeForm();
    },
    async createCard(data) {
      this.loading = "load";
      try {
        if (data.file) {
          const { url } = await UploadService.upload(data.file);
          data.url = url;
          delete data.file;
        }
        await CardService.create(data);
        this.fetchCard();
        this.fetchCoupon();
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
      this.closeForm();
    },
    async updateCoupons(couponIds) {
      let confirmed = window.confirm(
        `${this.$t("app.confirm-update")} \n(${this.$t("sidebar.menu.card")} ${
          this.editingData.name
        })`
      );
      if (confirmed) {
        this.loading = "load";
        try {
          await CardService.setCoupons(this.editingData.id, couponIds);
          this.closeCoupon();
          await this.fetchCard();
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }
      this.closeForm();
    },
  },
};
</script>
