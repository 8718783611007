import BaseRepo from '@/repos/BaseRepo';

class UploadRepo {
    upload(file) {
        const formData = new FormData();
        formData.append("file", file);
        return BaseRepo.post("/bo/uploader/", formData);
    }
}

export default new UploadRepo();