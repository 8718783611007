<template>
  <div class="container px-4 min-h-screen">
    <loader
      :model="loading"
      :detail="error"
      @close="
        loading = null;
        error = null;
      "
    />
    <Head />
    <Detail
      :model="selectedData"
      @edit="showForm"
      @delete="deleteSegment"
      @close="closeDetail"
    />
    <Form
      :active="isShowForm"
      :model="editingData"
      @close="closeForm"
      @onSubmitUpdate="updateSegment"
      @onSubmitCreate="createSegment"
    />
    <Users
      :active="isShowUsers"
      :model="editingData"
      :users="users"
      @close="closeUsers"
      @onSubmit="updateUsers"
    />
    <div class="flex flex-wrap">
      <Search @onApply="applySearch" />
    </div>
    <div class="flex flex-wrap">
      <Table
        name="Segments"
        :records="records"
        :page="page"
        :totalPage="totalPage"
        @onRecClick="showDetail"
        @onUserClick="showUsers"
        @onAddClick="() => showForm(null)"
        @onPageChange="
          (toPage) => {
            page = toPage;
            fetchSegment();
          }
        "
        @onSortChange="
          (query) => {
            ordering = query;
            fetchSegment();
          }
        "
      />
    </div>
  </div>
</template>
<script>
import Head from "@/components/Segments/Head.vue";
import Table from "@/components/Segments/Table.vue";
import Search from "@/components/Segments/Search.vue";
import Detail from "@/components/Segments/Detail.vue";
import Form from "@/components/Segments/Form.vue";
import Users from "@/components/Segments/Users.vue";

import { SegmentService, UserService } from "@/services";

export default {
  name: "segment-page",
  data() {
    return {
      selectedData: null,
      editingData: null,
      isShowForm: false,
      isShowUsers: false,
      users: [],
      records: [],
      page: 1,
      totalPage: 1,
      search: null,
      ordering: null,
      loading: null,
      error: null,
    };
  },
  components: {
    Head,
    Table,
    Search,
    Detail,
    Form,
    Users,
  },
  async mounted() {
    this.loading = "load";
    try {
      const data = await SegmentService.list();
      this.records = data.results;
      this.page = data.page;
      this.totalPage = data.totalPage;
      const userData = await UserService.list({
        page_size: 9999,
      });
      this.users = userData.results;
      this.loading = "success";
    } catch (e) {
      this.loading = "fail";
      this.error = e.message;
    }
  },
  methods: {
    async fetchSegment() {
      const data = await SegmentService.list({
        page: this.page,
        search: this.search,
        ordering: this.ordering,
      });
      this.records = data.results;
      this.page = data.page;
      this.totalPage = data.totalPage;
    },
    applySearch(form) {
      this.loading = "load";
      try {
        this.search = form.keyword || null;
        this.page = 1;
        this.fetchSegment();
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    showDetail(data) {
      this.closeForm();
      this.closeUsers();

      let isSwitch = this.selectedData && data != this.selectedData;
      if (isSwitch) {
        this.closeDetail();
        setTimeout(() => {
          this.selectedData = data;
        }, 300);
      } else if (data == this.selectedData) {
        this.closeDetail();
      } else {
        this.selectedData = data;
      }
    },
    closeDetail() {
      this.selectedData = null;
    },
    showForm(data) {
      this.closeDetail();
      this.closeUsers();
      if (this.isShowForm) {
        this.closeForm();
        setTimeout(() => {
          this.isShowForm = true;
          this.editingData = data;
        }, 300);
      } else {
        this.isShowForm = true;
        this.editingData = data;
      }
    },
    closeForm() {
      this.isShowForm = false;
      this.editingData = null;
    },
    async deleteSegment(data) {
      let confirmed = window.confirm(
        `${this.$t("app.confirm-delete")} \n(${this.$t(
          "sidebar.menu.segment"
        )} ${data.name})`
      );
      if (confirmed) {
        this.loading = "load";
        try {
          await SegmentService.delete(data.id);
          this.fetchSegment();
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }

      this.closeDetail();
    },
    async updateSegment(data) {
      let confirmed = window.confirm(
        `${this.$t("app.confirm-update")} \n(${this.$t(
          "sidebar.menu.segment"
        )} ${data.name})`
      );
      if (confirmed) {
        this.loading = "load";
        try {
          await SegmentService.update(data.id, data);
          this.fetchSegment();
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }
      this.closeForm();
    },
    async createSegment(data) {
      this.loading = "load";
      try {
        await SegmentService.create(data);
        this.fetchSegment();
        this.closeForm();
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    showUsers(data) {
      this.closeDetail();
      this.closeForm();
      if (this.isShowUsers) {
        this.closeUsers();
        setTimeout(() => {
          this.isShowUsers = true;
          this.editingData = data;
        }, 300);
      } else {
        this.isShowUsers = true;
        this.editingData = data;
      }
    },
    closeUsers() {
      this.isShowUsers = false;
      this.editingData = null;
    },
    async updateUsers(data) {
      let confirmed = window.confirm(`${this.$t("app.confirm-update")}`);
      if (confirmed) {
        this.loading = "load";
        try {
          await SegmentService.setUsers(this.editingData.id, data);
          this.fetchSegment();
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }
      this.closeUsers();
    },
  },
};
</script>
