<template>
  <nav
    class="
      right-nav
      fixed
      top-0
      bottom-0
      overflow-y-auto overflow-hidden
      shadow-xl
      bg-bg_color_1-100
      w-64
      z-65
      py-4
      px-6
      h-full
    "
    :class="active ? 'active' : 'inactive'"
  >
    <div class="flex justify-start">
      <a
        class="
          text-text_color_1-400
          block
          cursor-pointer
          px-4
          py-4
          border
          border-border_color_1-400
          rounded-lg
        "
        @click="$emit('close')"
      >
        <i class="fas fa-angle-double-right"></i>
      </a>
    </div>
    <div v-if="active" class="flex flex-col items-center">
      <div class="w-full px-4 flex justify-center">
        <h3
          class="
            text-xl
            font-semibold
            leading-normal
            text-text_color_1-700
            mb-2
          "
        >
          {{ $t("cards.coupon.title") }}
        </h3>
      </div>
      <div class="relative w-full">
        <label
          class="block uppercase text-text_color_1-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          {{ $t("app.search-keyword") }}
        </label>
        <input
          v-model="keyword"
          type="text"
          class="
            border-0
            px-3
            py-1
            placeholder-placeholder_color_1-300
            text-text_color_1-600
            bg-bg_color_white
            rounded
            text-sm
            shadow
            focus:outline-none
            focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        />
      </div>
      <div class="flex flex-wrap justify-start">
        <div class="w-full px-4 pt-2">
          <div
            v-for="(coupon, i) in showCoupons"
            :key="i"
            class="flex w-full items-center my-4"
          >
            <input
              :disabled="isExpired(coupon.expired_at)"
              v-model="form[coupon.id]"
              type="checkbox"
              class="
                border-0
                px-4
                py-4
                mr-4
                placeholder-placeholder_color_1-300
                text-text_color_1-600
                bg-bg_color_white
                rounded
                text-sm
                shadow
                focus:outline-none
                focus:ring
                ease-linear
                transition-all
                duration-150
              "
            />
            <label
              class="block text-xs font-bold mb-2"
              :class="
                isExpired(coupon.expired_at)
                  ? 'text-text_color_red-600'
                  : 'text-text_color_1-600'
              "
            >
              {{ `${coupon.name} (${coupon.code})` }}
            </label>
          </div>
        </div>

        <div class="flex flex-wrap mt-20">
          <button
            class="
              bg-btn_green_color_active-500
              text-text_color_white
              active:bg-btn_green_color_active-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            @click="save"
          >
            <i class="fas fa-save"></i> {{ $t("app.button.save") }}
          </button>
          <button
            class="
              bg-btn_gray_color_active-50
              text-text_color_1-600
              active:bg-btn_gray_color_active-200
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            @click.prevent="reset"
          >
            <i class="fas fa-undo-alt"></i> {{ $t("app.button.reset") }}
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>
); }

<script>
import defaultAvatar from "@/assets/img/default-avatar.jpg";

export default {
  data() {
    return {
      defaultAvatar,
      keyword: "",
      showCoupons: [],
      form: {},
    };
  },
  props: {
    active: {
      default: false,
    },
    records: {
      default: [],
    },
    model: {
      default: null,
    },
  },
  emits: ["onSubmit"],
  methods: {
    save() {
      const couponId = Object.keys(this.form).filter((id) => this.form[id]);
      this.$emit("onSubmit", couponId);
    },
    reset() {
      let confirmed = window.confirm(`${this.$t("app.confirm-reset")}`);
      if (confirmed) {
        this.form =
          {
            ...this.model,
          } || {};
      }
    },
    isExpired(date) {
      if (!date) return false;
      return this.$moment(date, "YYYY-MM-DDTHH:mm:ssZ").isBefore(this.$moment());
    }
  },
  watch: {
    model(val) {
      this.form =
        {
          ...val,
        } || {};
    },
    coupons(val) {
      this.showCoupons = val || [];
    },
    keyword(val) {
      if (!val) {
        this.showCoupons = this.coupons;
      } else {
        this.showCoupons = this.coupons.filter((c) => {
          return (
            (c.name && c.name.includes(val)) ||
            (c.name_en && c.name_en.includes(val)) ||
            (c.code && c.code.includes(val))
          );
        });
      }
    }
  },
  computed: {
    coupons() {
      if (!this.records) return [];
      let coupons = [...this.records];
      coupons = coupons.filter((c) => c.collect_method === "exclusive");
      coupons = coupons.sort((a, b) => {
        if (!b.expired_at) return 1;
        if (!a.expired_at) return -1;
        const aDate = this.$moment(a.expired_at, "YYYY-MM-DDTHH:mm:ssZ");
        const bDate = this.$moment(b.expired_at, "YYYY-MM-DDTHH:mm:ssZ");
        if (aDate.isSame(bDate)) return 0;
        if (aDate.isAfter(bDate)) return -1;
        return 1;
      });

      return coupons;
    },
  },
};
</script>

<style scoped>
.right-nav {
  left: auto;
  right: 0;
  width: 50%;
  max-width: 50%;
}

@media (max-width: 768px) {
  .right-nav {
    left: auto;
    right: 0;
    width: 100%;
    max-width: 100%;
  }
}

.active {
  transform: translatex(0);
  transition: all 0.2s ease-out;
}

.inactive {
  transform: translatex(100%);
  transition: all 0.2s ease-out;
}
</style>