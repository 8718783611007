import BaseRepo from '@/repos/BaseRepo';

const API_PATH = '/bo/staffs/';

class StaffRepo {
    list(params) {
        return BaseRepo.get(API_PATH, { params });
    }

    get(id, params) {
        return BaseRepo.get(`${API_PATH}${id}/`, { params });
    }

    create(rec) {
        return BaseRepo.post(API_PATH, rec);
    }

    update(id, rec) {
        return BaseRepo.put(`${API_PATH}${id}/`, rec);
    }

    delete(id) {
        return BaseRepo.delete(`${API_PATH}${id}/`);
    }

    setDepartments(id, departments) {
        return BaseRepo.post(`${API_PATH}${id}/departments/`, departments);
    }

    changePassword(id, newPassword) {
        return BaseRepo.put(`${API_PATH}${id}/password/`, newPassword);
    }
}

export default new StaffRepo();