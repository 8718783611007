import BaseRepo from '@/repos/BaseRepo';

class QuestionRepo {
    listHealth() {
        return BaseRepo.get("member/question/health/");
    }

    listInterest() {
        return BaseRepo.get("member/question/interest/");
    }
}

export default new QuestionRepo();