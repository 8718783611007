<template>
  <div class="h-screen min-h-screen">
    <iframe
      height="100%" width="100%"
      :src="dashboardUrl"
      frameborder="0"
      style="border: 0;height:100%;width:100%;min-height:100vh;"
      allowfullscreen
    ></iframe>
    <!-- <div class="flex flex-wrap">
      <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
        <card-line-chart />
      </div>
      <div class="w-full xl:w-4/12 px-4">
        <card-bar-chart />
      </div>
    </div>
    <div class="flex flex-wrap mt-4">
      <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
        <card-page-visits />
      </div>
      <div class="w-full xl:w-4/12 px-4">
        <card-social-traffic />
      </div>
    </div> -->
  </div>
</template>
// <script>
// import CardLineChart from "@/components/ExCards/CardLineChart.vue";
// import CardBarChart from "@/components/ExCards/CardBarChart.vue";
// import CardPageVisits from "@/components/ExCards/CardPageVisits.vue";
// import CardSocialTraffic from "@/components/ExCards/CardSocialTraffic.vue";
import { dashboardUrl } from '@/configs/api.config'
export default {
  name: "dashboard-page",
  data() {
    return {
      dashboardUrl
    }
  }
  // components: {
  //   CardLineChart,
  //   CardBarChart,
  //   CardPageVisits,
  //   CardSocialTraffic,
  // },
};
</script>
