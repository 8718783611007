<template>
  <div class="container px-4 min-h-screen">
    <loader
      :model="loading"
      :detail="error"
      @close="
        loading = null;
        error = null;
      "
    />
    <Head />
    <div class="flex flex-wrap">
      <User @onDownload="downloadUser" />
      <CouponUsage @onDownload="downloadUsageHistory" />
      <LineHistory @onDownload="downloadCouponBroadcast" />
      <Coupon @onDownload="downloadCoupon" />
      <Card @onDownload="downloadCard" />
      <Summary @onDownload="downloadSummary" />
    </div>
  </div>
</template>
<script>
import Head from "@/components/Report/Head.vue";
import User from "@/components/Report/User.vue";
import CouponUsage from "@/components/Report/CouponUsage.vue";
import LineHistory from "@/components/Report/LineHistory.vue";
import Coupon from "@/components/Report/Coupon.vue";
import Card from "@/components/Report/Card.vue";
import Summary from "@/components/Report/Summary.vue";
import fileDownload from "js-file-download";
import { ReportService } from "@/services";

export default {
  name: "report-page",
  data() {
    return {
      loading: null,
      error: null,
    };
  },
  components: {
    Head,
    User,
    CouponUsage,
    LineHistory,
    Coupon,
    Card,
    Summary,
  },
  async mounted() {
    this.loading = "load";
    try {
      this.loading = "success";
    } catch (e) {
      this.loading = "fail";
      this.error = e.message;
    }
  },
  methods: {
    async downloadUser(query) {
      this.loading = "load";
      try {
        const { data } = await ReportService.downloadUser(query);
        const fileName = `${Date.now()}_user_report.xls`;
        fileDownload(data, fileName);
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    async downloadUsageHistory(query) {
      this.loading = "load";
      try {
        const { data } = await ReportService.downloadUsageHistory(query);
        const fileName = `${Date.now()}_coupon_usage_report.xls`;
        fileDownload(data, fileName);
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    async downloadCard(query) {
      this.loading = "load";
      try {
        const { data } = await ReportService.downloadCard(query);
        const fileName = `${Date.now()}_card_report.xls`;
        fileDownload(data, fileName);
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    async downloadCoupon(query) {
      this.loading = "load";
      try {
        const { data } = await ReportService.downloadCoupon(query);
        const fileName = `${Date.now()}_coupon_report.xls`;
        fileDownload(data, fileName);
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    async downloadCouponBroadcast(query) {
      this.loading = "load";
      try {
        const { data } = await ReportService.downloadCouponBroadcast(query);
        const fileName = `${Date.now()}_coupon_broadcast_report.xls`;
        fileDownload(data, fileName);
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    async downloadSummary(query) {
      this.loading = "load";
      try {
        const { data } = await ReportService.downloadSummary(query);
        const fileName = `${Date.now()}_summary_report.xls`;
        fileDownload(data, fileName);
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
  },
};
</script>
