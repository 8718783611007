import { EventRepo } from "@/repos";
import { pageSize } from '@/configs/api.config'

const perPage = pageSize || 10;

class EventService {
    async list(params) {
        params = params || {};
        params['page_size'] = params['page_size'] || perPage;

        let { data } = await EventRepo.list(params);
        data.page = params.page || 1;
        data.totalPage = Math.ceil(data.count / params['page_size']);

        return data;
    }

    async get(id) {
        let { data } = await EventRepo.get(id);
        return data;
    }

    async create(user) {
        let { data } = await EventRepo.create(user);
        return data;
    }
    
    async update(id, user) {
        let { data } = await EventRepo.update(id, user);
        return data;
    }

    async delete(id) {
        let { data } = await EventRepo.delete(id);
        return data;
    }

    async setCards(id, cards) {
        let { data } = await EventRepo.setCards(id, cards);
        return data;
    }
}

export default new EventService();