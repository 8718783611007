import BaseRepo from '@/repos/BaseRepo';

const API_PATH = '/api-token-auth';

class AuthRepo {
    login(form) {
        return BaseRepo.post(API_PATH, form);
    }
}

export default new AuthRepo();