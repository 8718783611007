import BaseRepo from '@/repos/BaseRepo';

const API_PATH = '/bo/acc_purchases/';

class AccPurchaseRepo {
    list(params) {
        return BaseRepo.get(API_PATH, { params });
    }

    import(file) {
        const formData = new FormData();
        formData.append("file", file);
        return BaseRepo.post("/account/import/purchase/", formData);
    }
}

export default new AccPurchaseRepo();