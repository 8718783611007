<template>
  <div class="container px-4 min-h-screen">
    <loader
      :model="loading"
      :detail="error"
      @close="
        loading = null;
        error = null;
      "
    />
    <Head />
    <div class="flex flex-wrap">
      <Search
        @onApply="
          (t) => {
            type = t;
            page = 1;
            ordering = null;
            fetchLineHistory();
          }
        "
      />
    </div>
    <div class="flex flex-wrap">
      <Table
        name="LineHistory"
        :records="records"
        :page="page"
        :totalPage="totalPage"
        @onPageChange="
          (toPage) => {
            page = toPage;
            fetchLineHistory();
          }
        "
        @onSortChange="
          (query) => {
            ordering = query;
            fetchLineHistory();
          }
        "
        @delete="cancelSchedule"
      />
    </div>
  </div>
</template>
<script>
import Head from "@/components/LineHistory/Head.vue";
import Table from "@/components/LineHistory/Table.vue";
import Search from "@/components/LineHistory/Search.vue";

import { NotificationService } from "@/services";

export default {
  name: "line-history-page",
  data() {
    return {
      selectedData: null,
      records: [],
      page: 1,
      totalPage: 1,
      ordering: null,
      loading: null,
      error: null,
      type: "single",
    };
  },
  components: {
    Head,
    Table,
    Search,
  },
  async mounted() {
    this.loading = "load";
    try {
      const data = await NotificationService.listSingle();
      this.records = data.results;
      this.page = data.page;
      this.totalPage = data.totalPage;
      this.loading = "success";
    } catch (e) {
      this.loading = "fail";
      this.error = e.message;
    }
  },
  methods: {
    async fetchLineHistory() {
      this.loading = "load";
      try {
        let data;
        if (this.type === "single") {
          data = await NotificationService.listSingle({
            page: this.page,
            ordering: this.ordering,
          });
        } else if (this.type === "multicast") {
          data = await NotificationService.listMulticast({
            page: this.page,
            ordering: this.ordering,
          });
        } else {
          data = await NotificationService.listBroadcast({
            page: this.page,
            ordering: this.ordering,
          });
        }
        this.records = data.results;
        this.page = data.page;
        this.totalPage = data.totalPage;
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    async cancelSchedule(id) {
      console.log(id)
      let confirmed = window.confirm(
        `${this.$t("app.confirm-delete")} \n(${this.$t(
          "sidebar.menu.line-history"
        )} ${id})`
      );
      if (confirmed) {
        this.loading = "load";
        try {
          if (this.type === "single") {
          await NotificationService.deleteSingle(id);
        } else if (this.type === "multicast") {
          await NotificationService.deleteMulticast(id);
        } else {
          await NotificationService.deleteBroadcast(id);
        }
          await this.fetchLineHistory();
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }
    },
  },
};
</script>
