<template>
  <div class="container px-4 min-h-screen">
    <loader :model="loading" :detail="error" @close="loading = null; error = null;" />
    <Head />
    <Import
      :active="isShowImport"
      @close="closeImport"
      @onSubmitImport="importAccPurchases"
    />
    <div class="flex flex-wrap">
      <Search @onApply="applySearch" />
    </div>
    <div class="flex flex-wrap">
      <Table
        name="Coupons"
        :records="records"
        :page="page"
        :totalPage="totalPage"
        @onImportClick="showImport"
        @onPageChange="
          (toPage) => {
            page = toPage;
            fetchAccPurchase();
          }
        "
        @onSortChange="
          (query) => {
            ordering = query;
            fetchAccPurchase();
          }
        "
      />
    </div>
  </div>
</template>
<script>
import Head from "@/components/AccPurchase/Head.vue";
import Table from "@/components/AccPurchase/Table.vue";
import Search from "@/components/AccPurchase/Search.vue";
import Import from "@/components/AccPurchase/Import.vue";

import { AccPurchaseService } from "@/services";

export default {
  name: "acc-purchase-page",
  data() {
    return {
      selectedData: null,
      isShowImport: false,
      records: [],
      page: 1,
      totalPage: 1,
      search: null,
      ordering: null,
      loading: null,
      error: null,
    };
  },
  components: {
    Head,
    Table,
    Search,
    Import,
  },
  async mounted() {
    this.loading = "load";
    try {
      const data = await AccPurchaseService.list();
      this.records = data.results;
      this.page = data.page;
      this.totalPage = data.totalPage;
      this.loading = "success";
    } catch (e) {
      this.loading = "fail";
      this.error = e.message;
    }
  },
  methods: {
    async fetchAccPurchase() {
      const data = await AccPurchaseService.list({
        page: this.page,
        search: this.search,
        ordering: this.ordering,
      });
      this.records = data.results;
      this.page = data.page;
      this.totalPage = data.totalPage;
    },
    applySearch(form) {
      this.loading = "load";
      try {
        this.search = form.keyword || null;
        this.page = 1;
        this.fetchAccPurchase();
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    showImport() {
      this.isShowImport = true;
    },
    async importAccPurchases(file) {
      let confirmed = window.confirm(`${this.$t("app.confirm-import")}`);
      if (confirmed) {
        this.loading = "load";
        try {
          await AccPurchaseService.import(file);
          this.fetchAccPurchase();
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }
      this.closeImport();
    },
    closeImport() {
      this.isShowImport = false;
    },
  },
};
</script>
