import { QuestionRepo } from "@/repos";

class QuestionService {
    async listHealth() {
        let { data } = await QuestionRepo.listHealth();
        return data;
    }

    async listInterest() {
        let { data } = await QuestionRepo.listInterest();
        return data;
    }
}

export default new QuestionService();