import BaseRepo from '@/repos/BaseRepo';

const API_PATH = '/bo/departments/';

class SegmentRepo {
    list(params) {
        return BaseRepo.get(API_PATH, { params });
    }

    get(id, params) {
        return BaseRepo.get(`${API_PATH}${id}/`, { params });
    }

    create(rec) {
        return BaseRepo.post(API_PATH, rec);
    }

    update(id, rec) {
        return BaseRepo.put(`${API_PATH}${id}/`, rec);
    }

    delete(id) {
        return BaseRepo.delete(`${API_PATH}${id}/`);
    }

    setStaffs(id, staffs) {
        return BaseRepo.post(`${API_PATH}${id}/staffs/`, staffs);
    }
}

export default new SegmentRepo();