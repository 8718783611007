<template>
  <div>
    <a
      class="text-text_color_1-500 block"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
      ref="popoverDropdownRef"
      class="
        bg-text_color_white
        text-base
        z-50
        float-left
        py-2
        list-none
        text-left
        rounded
        shadow-lg
        min-w-48
      "
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <a
        class="
          text-sm
          py-2
          px-4
          font-normal
          cursor-pointer
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-text_color_1-700
          hover:text-text_color_1-400
        "
        @click.prevent="clickEvent"
      >
        {{ $t("sidebar.menu.event") }}
      </a>
      <a
        class="
          text-sm
          py-2
          px-4
          font-normal
          cursor-pointer
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-text_color_1-700
          hover:text-text_color_1-400
        "
        @click.prevent="clickSegment"
      >
        {{ $t("sidebar.menu.segment") }}
      </a>
      <a
        class="
          text-sm
          py-2
          px-4
          font-normal
          cursor-pointer
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-text_color_1-700
          hover:text-text_color_1-400
        "
        @click.prevent="clickAccPurchase"
      >
        {{ $t("sidebar.menu.acc_purchase") }}
      </a>
      <a
        v-if="lineEnable"
        class="
          text-sm
          py-2
          px-4
          font-normal
          cursor-pointer
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-text_color_1-700
          hover:text-text_color_1-400
        "
        @click.prevent="clickLine"
      >
        {{ $t("sidebar.menu.line") }}
      </a>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

export default {
  data() {
    return {
      dropdownPopoverShow: false,
    };
  },
  props: {
    lineEnable: {
      default: false,
    },
  },
  emits: [
    "onEventClick",
    "onSegmentClick",
    "onAccPurchaseClick",
    "onLineClick",
  ],
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    clickEvent() {
      this.dropdownPopoverShow = false;
      this.$emit("onEventClick");
    },
    clickSegment() {
      this.dropdownPopoverShow = false;
      this.$emit("onSegmentClick");
    },
    clickAccPurchase() {
      this.dropdownPopoverShow = false;
      this.$emit("onAccPurchaseClick");
    },
    clickLine() {
      this.dropdownPopoverShow = false;
      this.$emit("onLineClick");
    },
  },
};
</script>
