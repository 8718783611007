<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-bg_color_1-100
      border-0
    "
  >
    <div class="flex items-center px-4 py-4 bg-bg_color_white">
      <h3 class="font-semibold text-lg text-text_color_1-700">
        {{ $t("line.multicast.title") }}
      </h3>
    </div>
    <div class="flex flex-col items-center">
      <div class="flex flex-wrap justify-start w-full">
        <div class="w-full px-4">
          <div class="w-full items-center my-4">
            <label
              class="
                block
                uppercase
                text-text_color_1-600
                text-xs
                font-bold
                mb-2
              "
              >{{ $t("line.multicast.message") }}
            </label>
            <textarea
              v-model="message"
              type="text"
              min="0"
              max="100"
              class="
                border-0
                border-border_color_1-500
                px-4
                py-1
                placeholder-placeholder_color_1-300
                text-text_color_1-600
                bg-bg_color_white
                rounded
                text-sm
                shadow
                focus:outline-none
                focus:ring
                ease-linear
                transition-all
                duration-150
                ml-2
                w-full
              "
            />
          </div>

          <div class="w-full px-4">
            <div class="w-full items-center my-4">
              <label
                class="
                  block
                  uppercase
                  text-text_color_1-600
                  text-xs
                  font-bold
                  mb-2
                "
                >{{ $t("line.broadcast.sent_at") }}
              </label>
              <div class="flex items-center gap-x-4">
                <datepicker
                  v-model="date"
                  :lowerLimit="today"
                  clearable
                  class="
                    border-0
                    px-3
                    py-1
                    placeholder-placeholder_color_1-300
                    text-text_color_1-600
                    bg-bg_color_white
                    rounded
                    text-sm
                    shadow
                    focus:outline-none
                    focus:ring
                    ease-linear
                    transition-all
                    duration-150
                  "
                >
                  <template v-slot:clear="{ onClear }">
                    <button @click="onClear">x</button>
                  </template>
                </datepicker>
                <label
                  class="
                    block
                    uppercase
                    text-text_color_1-600
                    text-xs
                    font-bold
                  "
                  >{{ $t("line.broadcast.hour") }}
                </label>
                <div class="flex gap-x-2">
                  <select
                    v-model="hour"
                    class="
                      border-0
                      placeholder-placeholder_color_1-300
                      text-text_color_1-600
                      bg-bg_color_white
                      rounded
                      text-sm
                      shadow
                      focus:outline-none
                      focus:ring
                      ease-linear
                      transition-all
                      duration-150
                      mr-2
                    "
                  >
                    <option
                      v-for="(hour, i) in validHours"
                      :key="i"
                      :value="hour.value"
                    >
                      {{ hour.label }}
                    </option>
                  </select>
                  <label
                    class="
                      block
                      uppercase
                      text-text_color_1-600
                      text-xs
                      font-bold
                    "
                    >{{ $t("line.broadcast.minute") }}
                  </label>
                  <select
                    v-model="min"
                    class="
                      border-0
                      placeholder-placeholder_color_1-300
                      text-text_color_1-600
                      bg-bg_color_white
                      rounded
                      text-sm
                      shadow
                      focus:outline-none
                      focus:ring
                      ease-linear
                      transition-all
                      duration-150
                    "
                  >
                    <option
                      v-for="(min, i) in validMins"
                      :key="i"
                      :value="min.value"
                    >
                      {{ min.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div>
            <label
              class="
                block
                uppercase
                text-text_color_1-600
                text-xs
                font-bold
                mb-2
              "
              >{{ $t("line.multicast.criteria") }}
            </label>
          </div>
          <div
            v-for="(filter, i) in form"
            :key="i"
            class="w-full items-center my-4"
          >
            <div class="flex items-center my-4">
              <select
                v-model="filter.operation"
                class="
                  border-0
                  border-border_color_1-500
                  px-2
                  pr-6
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  ease-linear
                  transition-all
                  duration-150
                "
              >
                <option value="and">
                  {{ $t("coupons.broadcast.operations.and") }}
                </option>
                <option value="or">
                  {{ $t("coupons.broadcast.operations.or") }}
                </option>
              </select>

              <select
                v-model="filter.key"
                class="
                  border-0
                  border-border_color_1-500
                  px-3
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                  ml-2
                "
              >
                <option value="age">
                  {{ $t("coupons.broadcast.key.age") }}
                </option>
                <option value="gender">
                  {{ $t("coupons.broadcast.key.gender") }}
                </option>
                <option value="birthday">
                  {{ $t("coupons.broadcast.key.birth_month") }}
                </option>
                <option value="custom_group">
                  {{ $t("coupons.broadcast.key.custom_group") }}
                </option>
                <option value="province">
                  {{ $t("coupons.broadcast.key.province") }}
                </option>
                <option value="district">
                  {{ $t("coupons.broadcast.key.district") }}
                </option>
                <option value="event_package_id">
                  {{ $t("coupons.broadcast.key.event_package_id") }}
                </option>
                <option value="member_card_id">
                  {{ $t("coupons.broadcast.key.member_card_id") }}
                </option>
                <option value="language">
                  {{ $t("coupons.broadcast.key.language") }}
                </option>
                <option value="health">
                  {{ $t("coupons.broadcast.key.health") }}
                </option>
                <option value="interest">
                  {{ $t("coupons.broadcast.key.interest") }}
                </option>
              </select>

              <button
                v-if="i > 0"
                class="
                  bg-btn_red_color_active-500
                  text-text_color_white
                  active:bg-btn_red_color_active-600
                  font-bold
                  uppercase
                  text-xs
                  px-4
                  py-2
                  rounded
                  shadow
                  hover:shadow-md
                  outline-none
                  focus:outline-none
                  ml-2
                  ease-linear
                  transition-all
                  duration-150
                "
                @click="
                  () => {
                    form.splice(i, 1);
                  }
                "
              >
                <i class="fas fa-minus-circle" />
              </button>
            </div>

            <!-- age -->
            <div v-if="filter.key === 'age'" class="flex items-center my-4">
              <label
                class="block uppercase text-text_color_1-600 text-xs font-bold"
                >{{ $t("coupons.broadcast.age.range") }}
              </label>
              <input
                v-model="filter.param1"
                type="number"
                min="0"
                max="100"
                class="
                  border-0
                  border-border_color_1-500
                  px-4
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  ease-linear
                  transition-all
                  duration-150
                  ml-2
                  w-4/12
                "
              />
              <label
                class="
                  block
                  uppercase
                  text-text_color_1-600
                  text-xs
                  font-bold
                  ml-2
                "
                >{{ $t("coupons.broadcast.age.to") }}
              </label>
              <input
                v-model="filter.param2"
                type="number"
                :min="filter.param1"
                max="100"
                class="
                  border-0
                  border-border_color_1-500
                  px-4
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  ease-linear
                  transition-all
                  duration-150
                  ml-2
                  w-4/12
                "
              />
            </div>

            <!-- gender -->
            <div v-if="filter.key === 'gender'" class="flex items-center my-4">
              <label
                class="block uppercase text-text_color_1-600 text-xs font-bold"
                >{{ $t("coupons.broadcast.gender.title") }}
              </label>
              <select
                v-model="filter.param1"
                class="
                  border-0
                  border-border_color_1-500
                  px-2
                  pr-6
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  ease-linear
                  transition-all
                  duration-150
                  ml-2
                  w-6/12
                "
              >
                <option value="Male">
                  {{ $t("coupons.broadcast.gender.male") }}
                </option>
                <option value="Female">
                  {{ $t("coupons.broadcast.gender.female") }}
                </option>
                <option value="Prefer not to say">
                  {{ $t("coupons.broadcast.gender.prefer_not_to_say") }}
                </option>
              </select>
            </div>

            <!-- birth_month -->
            <div v-if="filter.key === 'birthday'" class="flex items-center my-4">
              <label
                class="block uppercase text-text_color_1-600 text-xs font-bold"
                >{{ $t("coupons.broadcast.birth_month.title") }}
              </label>
              <select
                v-model="filter.param1"
                class="
                  border-0
                  px-2
                  pr-6
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  ease-linear
                  transition-all
                  duration-150
                  ml-2
                  w-6/12
                "
              >
                <option :value="1">
                  {{ $t("coupons.broadcast.birth_month.january") }}
                </option>
                <option :value="2">
                  {{ $t("coupons.broadcast.birth_month.february") }}
                </option>
                <option :value="3">
                  {{ $t("coupons.broadcast.birth_month.march") }}
                </option>
                <option :value="4">
                  {{ $t("coupons.broadcast.birth_month.april") }}
                </option>
                <option :value="5">
                  {{ $t("coupons.broadcast.birth_month.may") }}
                </option>
                <option :value="6">
                  {{ $t("coupons.broadcast.birth_month.june") }}
                </option>
                <option :value="7">
                  {{ $t("coupons.broadcast.birth_month.july") }}
                </option>
                <option :value="8">
                  {{ $t("coupons.broadcast.birth_month.august") }}
                </option>
                <option :value="9">
                  {{ $t("coupons.broadcast.birth_month.september") }}
                </option>
                <option :value="10">
                  {{ $t("coupons.broadcast.birth_month.october") }}
                </option>
                <option :value="11">
                  {{ $t("coupons.broadcast.birth_month.november") }}
                </option>
                <option :value="12">
                  {{ $t("coupons.broadcast.birth_month.december") }}
                </option>
              </select>
            </div>

            <!-- language -->
            <div
              v-if="filter.key === 'language'"
              class="flex items-center my-4"
            >
              <label
                class="block uppercase text-text_color_1-600 text-xs font-bold"
                >{{ $t("coupons.broadcast.language.title") }}
              </label>
              <select
                v-model="filter.param1"
                class="
                  border-0
                  border-border_color_1-500
                  px-2
                  pr-6
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  ease-linear
                  transition-all
                  duration-150
                  ml-2
                  w-6/12
                "
              >
                <option value="th">
                  {{ $t("coupons.broadcast.language.th") }}
                </option>
                <option value="en">
                  {{ $t("coupons.broadcast.language.en") }}
                </option>
              </select>
            </div>

            <!-- province -->
            <div
              v-if="filter.key === 'province'"
              class="flex items-center my-4"
            >
              <label
                class="block uppercase text-text_color_1-600 text-xs font-bold"
                >{{ $t("coupons.broadcast.province.title") }}
              </label>
              <select
                v-model="filter.param1"
                class="
                  border-0
                  border-border_color_1-500
                  px-3
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  ease-linear
                  transition-all
                  duration-150
                  w-6/12
                  ml-2
                "
              >
                <option
                  v-for="(province, i) in provinces.filter((p) => p.name)"
                  :key="i"
                  :value="province.name.th"
                >
                  {{ province.name.th }}
                </option>
              </select>
            </div>

            <!-- district -->
            <div
              v-if="filter.key === 'district'"
              class="flex items-center my-4"
            >
              <label
                class="block uppercase text-text_color_1-600 text-xs font-bold"
                >{{ $t("coupons.broadcast.district.title") }}
              </label>
              <input
                v-model="filter.param1"
                type="text"
                class="
                  border-0
                  border-border_color_1-500
                  px-4
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  ease-linear
                  transition-all
                  duration-150
                  ml-2
                  w-6/12
                "
              />
            </div>

            <!-- custom_group -->
            <div
              v-if="filter.key === 'custom_group'"
              class="flex items-center my-4"
            >
              <label
                class="block uppercase text-text_color_1-600 text-xs font-bold"
                >{{ $t("coupons.broadcast.custom_group.title") }}
              </label>
              <select
                v-model="filter.param1"
                class="
                  border-0
                  border-border_color_1-500
                  px-3
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  ease-linear
                  transition-all
                  duration-150
                  w-6/12
                  ml-2
                "
              >
                <option v-for="(data, i) in segments" :key="i" :value="data.id">
                  {{ data.name }}
                </option>
              </select>
            </div>

            <!-- event_package_id -->
            <div
              v-if="filter.key === 'event_package_id'"
              class="flex items-center my-4"
            >
              <label
                class="block uppercase text-text_color_1-600 text-xs font-bold"
                >{{ $t("coupons.broadcast.event_package_id.title") }}
              </label>
              <select
                v-model="filter.param1"
                class="
                  border-0
                  border-border_color_1-500
                  px-3
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  ease-linear
                  transition-all
                  duration-150
                  w-6/12
                  ml-2
                "
              >
                <option
                  v-for="(data, i) in eventPackages"
                  :key="i"
                  :value="data.id"
                >
                  {{ data.member_card_set_code }}
                </option>
              </select>
            </div>

            <!-- member_card_id -->
            <div
              v-if="filter.key === 'member_card_id'"
              class="flex items-center my-4"
            >
              <label
                class="block uppercase text-text_color_1-600 text-xs font-bold"
                >{{ $t("coupons.broadcast.member_card_id.title") }}
              </label>
              <select
                v-model="filter.param1"
                class="
                  border-0
                  border-border_color_1-500
                  px-3
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  ease-linear
                  transition-all
                  duration-150
                  w-6/12
                  ml-2
                "
              >
                <option v-for="(data, i) in cards" :key="i" :value="data.id">
                  {{ `Level ${data.level} ${data.name}` }}
                </option>
              </select>
            </div>

            <!-- health -->
            <div v-if="filter.key === 'health'" class="flex items-center my-4">
              <label
                class="block uppercase text-text_color_1-600 text-xs font-bold"
                >{{ $t("coupons.broadcast.health.title") }}
              </label>
              <select
                v-model="filter.param1"
                class="
                  border-0
                  border-border_color_1-500
                  px-3
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  ease-linear
                  transition-all
                  duration-150
                  w-6/12
                  ml-2
                "
              >
                <option
                  v-for="(data, i) in health.choice"
                  :key="i"
                  :value="data.id"
                >
                  {{ data.name }}
                </option>
              </select>
              <input
                v-if="filter.param1 == healthOther"
                v-model="filter.param2"
                type="text"
                max="100"
                class="
                  border-0
                  border-border_color_1-500
                  px-4
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  ease-linear
                  transition-all
                  duration-150
                  ml-2
                  w-4/12
                "
              />
            </div>

            <!-- interest -->
            <div
              v-if="filter.key === 'interest'"
              class="flex items-center my-4"
            >
              <label
                class="block uppercase text-text_color_1-600 text-xs font-bold"
                >{{ $t("coupons.broadcast.interest.title") }}
              </label>
              <select
                v-model="filter.param1"
                class="
                  border-0
                  border-border_color_1-500
                  px-3
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  ease-linear
                  transition-all
                  duration-150
                  w-6/12
                  ml-2
                "
              >
                <option
                  v-for="(data, i) in interest.choice"
                  :key="i"
                  :value="data.id"
                >
                  {{ data.name }}
                </option>
              </select>
              <input
                v-if="filter.param1 == interestOther"
                v-model="filter.param2"
                type="text"
                max="100"
                class="
                  border-0
                  border-border_color_1-500
                  px-4
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  ease-linear
                  transition-all
                  duration-150
                  ml-2
                  w-4/12
                "
              />
            </div>
          </div>

          <button
            class="
              bg-btn_blue_color_active-500
              text-text_color_white
              active:bg-btn_blue_color_active-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            @click="
              () => {
                form.push({ operation: 'and' });
              }
            "
          >
            <i class="fas fa-plus-circle" />
          </button>
        </div>

        <div class="flex flex-wrap px-4 py-4">
          <button
            class="
              bg-btn_green_color_active-500
              text-text_color_white
              active:bg-btn_green_color_active-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            @click="save"
          >
            <i class="fas fa-paper-plane"></i> {{ $t("app.button.multicast") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
); }

<script>
import Datepicker from "vue3-datepicker";

export default {
  data() {
    return {
      form: [{ operation: 'and' }],
      date: null,
      hour: null,
      min: null,
      message: "",
    };
  },
  components: {
    Datepicker,
  },
  props: {
    active: {
      default: false,
    },
    records: {
      default: [],
    },
    segments: {
      default: [],
    },
    eventPackages: {
      default: [],
    },
    cards: {
      default: [],
    },
    health: {
      default: {},
    },
    interest: {
      default: {},
    },
    provinces: {
      default: [],
    },
  },
  emits: ["onSubmit"],
  methods: {
    save() {
      this.$emit("onSubmit", {
        filters: JSON.stringify(this.cleansingFilters(this.form)),
        message: this.message,
        is_send_now: !this.resolveTime(),
        send_at: this.resolveTime(),
      });
    },
    resolveTime() {
      if (this.date === null || this.hour === null || this.min === null)
        return null;
      const datetime = this.$moment(this.date)
        .set("hour", this.hour)
        .set("minute", this.min)
        .set("second", 0)
        .format();
      return datetime;
    },
    cleansingFilters(filters) {
      return filters.map((f) => {
        let cleanF = { ...f };
        if (!cleanF.param2) {
          cleanF.param2 = null;
        }
        return cleanF;
      });
    },
  },
  watch: {
    date(newVal, oldVal) {
      if (newVal != oldVal) {
        this.hour = null;
        this.min = null;
      }
    },
  },
  computed: {
    healthOther: function () {
      return this.health.choice.find((c) => c.is_other).id;
    },
    interestOther: function () {
      return this.interest.choice.find((c) => c.is_other).id;
    },
    today() {
      return new Date();
    },
    validHours() {
      let d = new Date();
      let h = 0;
      let currentH = d.getHours();
      if (this.date && this.$moment(this.date).isSame(d, "day")) {
        h = currentH;
      }
      let hours = [{ value: null, label: "--" }];
      for (let i = h; i < 24; i++) {
        let label = (i + "").padStart(2, "0");
        hours.push({ value: i, label });
      }

      return hours;
    },
    validMins() {
      let d = new Date();
      let h = d.getHours();
      let hLabel = (h + "").padStart(2, "0");
      let m = 0;
      if (
        this.date &&
        this.$moment(this.date).isSame(d, "day") &&
        this.hour &&
        this.hour == hLabel
      ) {
        m = d.getMinutes();
      }
      if (m % 5 != 0) {
        m += 5 - (m % 5);
      }
      let mins = [{ value: null, label: "--" }];
      for (let i = m; i < 60; i += 5) {
        let label = (i + "").padStart(2, "0");
        mins.push({ value: i, label });
      }

      return mins;
    },
  },
};
</script>

<style scoped>
</style>