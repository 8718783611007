<template>
  <nav
    class="
      right-nav
      fixed
      top-0
      bottom-0
      overflow-y-auto overflow-hidden
      shadow-xl
      bg-bg_color_white
      w-64
      z-60
      py-4
      px-6
      h-full
    "
    :class="model ? 'active' : 'inactive'"
  >
    <div class="flex justify-start">
      <a
        class="
          text-text_color_1-400
          block
          cursor-pointer
          px-4
          py-4
          border
          border-border_color_1-400
          rounded-lg
        "
        @click="$emit('close')"
      >
        <i class="fas fa-angle-double-right"></i>
      </a>
    </div>

    <div v-if="model" class="flex flex-col items-center">
      <div class="flex flex-wrap justify-center">
        <!-- Info -->
        <div class="w-full px-4 md:px-8">
          <div class="flex justify-center py-4 pt-8">
            <h3
              class="
                text-xl
                font-semibold
                leading-normal
                text-text_color_1-700
                mb-2
              "
            >
              {{ $t("sidebar.menu.staff") }}
            </h3>
          </div>

          <div class="mb-2 text-text_color_1-600">
            <span class="min-w-80-px inline-block text-right pr-2">
              <span class="font-bold text-text_color_1-400">{{
                `${$t("staffs.form.first_name")} - ${$t("staffs.form.last_name")}`
              }}</span>
            </span>
            {{ `${model.first_name} ${model.last_name}` }}
          </div>

          <div class="mb-2 text-text_color_1-600">
            <span class="min-w-80-px inline-block text-right pr-2">
              <span class="font-bold text-text_color_1-400">{{
                $t("staffs.form.username")
              }}</span>
            </span>
            {{ model.username }}
          </div>

          <div class="mb-2 text-text_color_1-600">
            <span class="min-w-80-px inline-block text-right pr-2">
              <span class="font-bold text-text_color_1-400">{{
                $t("staffs.form.code")
              }}</span>
            </span>
            {{ model.code }}
          </div>
        </div>

        <!-- Child Department -->
        <div class="w-full px-4 md:px-8">
          <hr class="my-6 border-b-1 border-border_color_1-300" />
          <div class="mb-2 text-text_color_1-600">
            <span class="mb-2 min-w-80-px inline-block text-right pr-2">
              <span class="font-bold text-text_color_1-400">{{
                $t("staffs.form.departments")
              }}</span>
            </span>
            <ol class="list-decimal list-inside">
              <li v-for="(department, i) in model.departments" :key="i">
                <span>{{ department.name || "-" }}</span>
              </li>
            </ol>
          </div>
        </div>

        <!-- Action -->
        <div class="flex flex-wrap gap-6 justify-center mt-20">
          <button
            class="
              bg-btn_yellow_color_active-500
              text-text_color_white
              active:bg-btn_yellow_color_active-600
              font-bold
              uppercase
              text-xs
              px-8
              py-4
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            type="button"
            @click="$emit('edit', model)"
          >
            <i class="fas fa-edit"></i> {{ $t("app.button.edit") }}
          </button>
          <button
            class="
              bg-btn_red_color_active-500
              text-text_color_white
              active:bg-btn_red_color_active-600
              font-bold
              uppercase
              text-xs
              px-8
              py-4
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            type="button"
            @click="$emit('delete', model)"
          >
            <i class="fas fa-trash"></i> {{ $t("app.button.delete") }}
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>
); }

<script>
export default {
  props: {
    model: {
      default: null,
    },
  },
  emits: ["delete", "edit", "close"],
  methods: {
    formatDate(val, format) {
      if (!val) return "-";
      return this.$moment(val).locale("th").format(format);
    },
  },
};
</script>

<style scoped>
.right-nav {
  left: auto;
  right: 0;
  width: 50%;
  max-width: 50%;
}

@media (max-width: 768px) {
  .right-nav {
    left: auto;
    right: 0;
    width: 100%;
    max-width: 100%;
  }
}

.active {
  transform: translatex(0);
  transition: all 0.2s ease-out;
}

.inactive {
  transform: translatex(100%);
  transition: all 0.2s ease-out;
}
</style>