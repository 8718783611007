<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded
      bg-bg_color_white
    "
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center justify-between">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <div class="flex items-center gap-6 justify-between md:justify-start">
            <h3 class="font-semibold text-lg text-text_color_1-700">
              {{ $t("app.table-title") }}
            </h3>
            <span class="ml-4 font-thin text-xs">
              <a
                class="px-4 py-4"
                @click="prevPage"
                :class="page > 1 ? 'cursor-pointer' : 'text-text_color_1-400'"
                ><i class="fas fa-chevron-left"
              /></a>
              {{ page }} / {{ totalPage }}
              <a
                class="px-4 py-4"
                :class="
                  page < totalPage ? 'cursor-pointer' : 'text-text_color_1-400'
                "
                @click="nextPage"
                ><i class="fas fa-chevron-right" /></a
            ></span>
          </div>
        </div>
        <div
          class="
            flex
            justify-start
            md:justify-end
            w-full
            px-4
            mt-2
            md:mt-0
            max-w-full
            flex-grow flex-1
          "
        >
        <button
            class="
              bg-btn_yellow_color_active-500
              text-text_color_white
              active:bg-btn_yellow_color_active-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            type="button"
            @click="handleBroadcastCouponClick"
          >
            <i class="fas fa-paper-plane"></i> {{ $t("coupons.button.broadcast") }}
          </button>
          <button
            class="
              bg-btn_green_color_active-500
              text-text_color_white
              active:bg-btn_green_color_active-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            type="button"
            @click="$emit('onAddClick')"
          >
            <i class="fas fa-plus"></i> {{ $t("app.button.add") }}
          </button>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
              "
            >
              {{ $t("coupons.table.name") }}
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
              "
            >
              {{ $t("coupons.table.hospital_code") }}
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
              "
              @click="sorting('type')"
            >
              {{ $t("coupons.table.type") }}
              <i class="fas" :class="getSortClass('type')"></i>
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
              "
              @click="sorting('collect_method')"
            >
              {{ $t("coupons.table.collect_method") }}
              <i class="fas" :class="getSortClass('collect_method')"></i>
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
              "
              @click="sorting('collect_expired_at')"
            >
              {{ $t("coupons.table.collect_started_at") }}
              <i class="fas" :class="getSortClass('collect_started_at')"></i>
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
              "
              @click="sorting('collect_expired_at')"
            >
              {{ $t("coupons.table.collect_expired_at") }}
              <i class="fas" :class="getSortClass('collect_expired_at')"></i>
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
              "
              @click="sorting('expired_at')"
            >
              {{ $t("coupons.table.expired_at") }}
              <i class="fas" :class="getSortClass('expired_at')"></i>
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
              "
              @click="sorting('created_at')"
            >
              {{ $t("app.table.created_at") }}
              <i class="fas" :class="getSortClass('created_at')"></i>
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-bg_color_2-50
                text-text_color_1-500
                border-border_color_1-100
              "
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(coupon, i) in records" :key="i">
            <td
              @click="$emit('onRecClick', coupon)"
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
                text-left
                flex
                items-center
                cursor-pointer
                hover:bg-bg_color_2-50
              "
            >
              <span class="ml-3 font-bold">
                {{ coupon.name }}
              </span>
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              {{ coupon.hospital_code }}
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              {{
                $t(`coupons.table.type_value_${coupon.type}`) ||
                $t("coupons.table.type_value_other")
              }}
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              {{
                $t(
                  `coupons.table.collect_method_value_${coupon.collect_method}`
                ) || $t("coupons.table.collect_method_other")
              }}
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              {{ formatDate(coupon.collect_started_at, "DD MMMM YYYY") }}
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              {{ formatDate(coupon.collect_expired_at, "DD MMMM YYYY") }}
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              {{ formatDate(coupon.expired_at, "DD MMMM YYYY") }}
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              {{ formatDate(coupon.created_at, "DD MMMM YYYY HH:mm") }}
            </td>
            <td
              v-if="coupon.collect_method === 'exclusive'"
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
                text-right
              "
            >
              <Dropdown
                @onBroadcastCouponClick="
                  () => handleBroadcastCouponClick(coupon)
                "
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import Dropdown from "@/components/Coupons/Dropdown.vue";

export default {
  data() {
    return {
      defaultSortStatus: {
        type: 0,
        collect_started_at: 0,
        collect_expired_at: 0,
        collect_method: 0,
        expired_at: 0,
        created_at: 0
      },
      sortStatus: {
        type: 0,
        collect_started_at: 0,
        collect_expired_at: 0,
        collect_method: 0,
        expired_at: 0,
        created_at: 0
      },
    };
  },
  components: {
    Dropdown,
  },
  emits: ["onAddClick", "onRecClick", "onSortChange", "onPageChange", "onBroadcastClick"],
  props: {
    records: {
      default: [],
    },
    page: {
      default: 1,
    },
    totalPage: {
      default: 1,
    },
  },
  methods: {
    formatDate(val, format) {
      if (!val) return "-";
      return this.$moment(val).locale("th").format(format);
    },
    getSortClass(field) {
      return ["fa-sort", "fa-sort-up", "fa-sort-down"][
        this.sortStatus[field] || 0
      ];
    },
    sorting(field) {
      const nextState =
        this.sortStatus[field] >= 2 ? 0 : this.sortStatus[field] + 1;
      this.sortStatus = { ...this.defaultSortStatus };
      this.sortStatus[field] = nextState;
      const orderingQuery = [null, `${field}`, `-${field}`][nextState];
      this.$emit("onSortChange", orderingQuery);
    },
    prevPage() {
      if (this.page > 1) this.$emit("onPageChange", this.page - 1);
    },
    nextPage() {
      if (this.page < this.totalPage) this.$emit("onPageChange", this.page + 1);
    },
    handleBroadcastCouponClick(card) {
      this.$emit("onBroadcastClick", card);
    }
  },
};
</script>
