<template>
  <nav
    class="
      right-nav
      fixed
      top-0
      bottom-0
      overflow-y-auto overflow-hidden
      shadow-xl
      bg-bg_color_1-100
      w-64
      z-65
      py-4
      px-6
      h-full
    "
    :class="active ? 'active' : 'inactive'"
  >
    <div class="flex justify-start">
      <a
        class="
          text-text_color_1-400
          block
          cursor-pointer
          px-4
          py-4
          border
          border-border_color_1-400
          rounded-lg
        "
        @click="$emit('close')"
      >
        <i class="fas fa-angle-double-right"></i>
      </a>
    </div>
    <div v-if="active" class="flex flex-col items-center">
      <div class="w-full px-4 flex justify-center">
        <h3
          class="
            text-xl
            font-semibold
            leading-normal
            text-text_color_1-700
            mb-2
          "
        >
          {{ $t("users.line.title") }}
        </h3>
      </div>
      <div class="flex flex-wrap w-full justify-start">
        <div class="w-full px-4 pt-2">
          <div class="flex w-full items-center my-4">
            <textarea
              v-model="form.message"
              type="text"
              min="0"
              max="100"
              class="
                border-0
                px-4
                py-1
                placeholder-placeholder_color_1-300
                text-text_color_1-600
                bg-bg_color_white
                rounded
                text-sm
                shadow
                focus:outline-none
                focus:ring
                ease-linear
                transition-all
                duration-150
                w-full
              "
            />
          </div>

          <!-- Coupons -->
          <div class="w-full items-center my-4">
            <div class="relative w-full">
              <label
                class="
                  block
                  uppercase
                  text-text_color_1-600
                  text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("coupons.broadcast.coupons") }}
              </label>
              <multiselect
                class="
                  border-0
                  px-3
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
                v-model="form.coupons"
                mode="multiple"
                :delay="3"
                loading
                searchable
                :hideSelected="false"
                :options="couponOpts"
              />
            </div>
          </div>
        </div>

        <div class="flex flex-wrap mt-4">
          <button
            class="
              bg-btn_green_color_active-500
              text-text_color_white
              active:bg-btn_green_color_active-600
              font-bold
              uppercase
              text-xs
              px-6
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            @click="save"
          >
            <i class="fas fa-paper-plane"></i> {{ $t("app.button.send") }}
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>
); }

<script>
export default {
  data() {
    return {
      form: {},
      couponOpts: [],
    };
  },
  props: {
    active: {
      default: false,
    },
    coupons: {
      default: [],
    }
  },
  emits: ["close", "onSubmit"],
  methods: {
    save() {
      this.form["is_send_now"] = true;
      const payload = { ...this.form }
      payload.coupons = JSON.stringify(payload.coupons);
      this.$emit("onSubmit", payload);
    }
  },
  watch: {
    active() {
      this.form = {};
    },
    coupons(val) {
      this.couponOpts = val
        .filter((u) => u && !!u.id)
        .map((u) => {
          return {
            value: u.id,
            label: `${u.name || "-"}`,
          };
        });
    },
  }
};
</script>

<style scoped>
.right-nav {
  left: auto;
  right: 0;
  width: 30%;
  max-width: 30%;
}

@media (max-width: 768px) {
  .right-nav {
    left: auto;
    right: 0;
    width: 100%;
    max-width: 100%;
  }
}

.active {
  transform: translatex(0);
  transition: all 0.2s ease-out;
}

.inactive {
  transform: translatex(100%);
  transition: all 0.2s ease-out;
}
</style>