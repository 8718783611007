<template>
  <div class="container px-4 min-h-screen">
    <loader
      :model="loading"
      :detail="error"
      @close="
        loading = null;
        error = null;
      "
    />
    <Head />
    <Detail :model="selectedData" @close="closeDetail" />
    <div class="flex flex-wrap">
      <Search @onApply="applySearch" />
    </div>
    <div class="flex flex-wrap">
      <Table
        name="Coupons"
        :records="records"
        :page="page"
        :totalPage="totalPage"
        @onRecClick="showDetail"
        @onPageChange="
          (toPage) => {
            page = toPage;
            fetchCouponUsage();
          }
        "
        @onSortChange="
          (query) => {
            ordering = query;
            fetchCouponUsage();
          }
        "
      />
    </div>
  </div>
</template>
<script>
import Head from "@/components/CouponUsages/Head.vue";
import Table from "@/components/CouponUsages/Table.vue";
import Search from "@/components/CouponUsages/Search.vue";
import Detail from "@/components/CouponUsages/Detail.vue";

import { CouponUsageService } from "@/services";

export default {
  name: "coupon-usage-page",
  data() {
    return {
      selectedData: null,
      records: [],
      page: 1,
      totalPage: 1,
      search: null,
      ordering: null,
      loading: null,
      error: null,
    };
  },
  components: {
    Head,
    Table,
    Search,
    Detail,
  },
  async mounted() {
    this.loading = "load";
    try {
      const data = await CouponUsageService.list();
      this.records = data.results;
      this.page = data.page;
      this.totalPage = data.totalPage;
      this.loading = "success";
    } catch (e) {
      this.loading = "fail";
      this.error = e.message;
    }
  },
  methods: {
    async fetchCouponUsage() {
      const data = await CouponUsageService.list({
        page: this.page,
        search: this.search,
        ordering: this.ordering,
      });
      this.records = data.results;
      this.page = data.page;
      this.totalPage = data.totalPage;
    },
    applySearch(form) {
      this.loading = "load";
      try {
        this.search = form.keyword || null;
        this.page = 1;
        this.fetchCouponUsage();
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    showDetail(data) {
      this.closeForm();

      let isSwitch = this.selectedData && data != this.selectedData;
      if (isSwitch) {
        this.closeDetail();
        setTimeout(() => {
          this.selectedData = data;
        }, 300);
      } else if (data == this.selectedData) {
        this.closeDetail();
      } else {
        this.selectedData = data;
      }
    },
    closeDetail() {
      this.selectedData = null;
    },
  },
};
</script>
