import { CouponUsageRepo } from "@/repos";
import { pageSize } from '@/configs/api.config'

const perPage = pageSize || 10;

class CouponUsageService {
    async list(params) {
        params = params || {};
        params['page_size'] = params['page_size'] || perPage;

        let { data } = await CouponUsageRepo.list(params);
        data.page = params.page || 1;
        data.totalPage = Math.ceil(data.count / params['page_size']);

        return data;
    }
}

export default new CouponUsageService();