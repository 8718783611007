import { CardRepo } from "@/repos";
import { pageSize } from '@/configs/api.config'

const perPage = pageSize || 10;

class CardService {
    async list(params) {
        params = params || {};
        params['page_size'] = params['page_size'] || perPage;

        let { data } = await CardRepo.list(params);
        data.page = params.page || 1;
        data.totalPage = Math.ceil(data.count / params['page_size']);

        return data;
    }

    async get(id) {
        let { data } = await CardRepo.get(id);
        return data;
    }

    async create(user) {
        let { data } = await CardRepo.create(user);
        return data;
    }
    
    async update(id, user) {
        let { data } = await CardRepo.update(id, user);
        return data;
    }

    async delete(id) {
        let { data } = await CardRepo.delete(id);
        return data;
    }

    async setCoupons(id, coupons) {
        let { data } = await CardRepo.setCoupons(id, coupons);
        return data;
    }
}

export default new CardService();