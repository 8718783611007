import { CouponRepo } from "@/repos";
import { pageSize } from '@/configs/api.config'

const perPage = pageSize || 10;

class CouponService {
    async list(params) {
        params = params || {};
        params['page_size'] = params['page_size'] || perPage;

        let { data } = await CouponRepo.list(params);
        data.page = params.page || 1;
        data.totalPage = Math.ceil(data.count / params['page_size']);

        return data;
    }

    async get(id) {
        let { data } = await CouponRepo.get(id);
        return data;
    }

    async create(user) {
        let { data } = await CouponRepo.create(user);
        return data;
    }
    
    async update(id, user) {
        let { data } = await CouponRepo.update(id, user);
        return data;
    }

    async delete(id) {
        let { data } = await CouponRepo.delete(id);
        return data;
    }

    async addToUsers(id, users) {
        let { data } = await CouponRepo.addToUsers(id, users);
        return data;
    }
}

export default new CouponService();