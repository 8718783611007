<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-bg_color_1-100
      border-0
    "
  >
    <div class="rounded-t bg-bg_color_white mb-0 px-6 py-3">
      <div class="text-center flex justify-between">
        <h6 class="text-text_color_1-700 text-xl font-bold">
          {{ $t("app.search-segment") }}
        </h6>
        <button
          class="
            bg-btn_blue_color_active-500
            text-text_color_white
            active:bg-btn_blue_color_active-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
          @click="search"
        >
          {{ $t("app.search-apply") }}
        </button>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form @submit.prevent="search">
        <input type="submit" hidden />
        <h6 class="text-text_color_1-400 text-sm mt-3 mb-3 font-bold uppercase">
          {{ $t("app.search-label") }}
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full">
              <label
                class="
                  block
                  uppercase
                  text-text_color_1-600
                  text-xs
                  font-bold
                  mb-2
                "
                htmlFor="grid-password"
              >
                {{ $t("app.search-keyword") }}
              </label>
              <input
                v-model="form.keyword"
                type="text"
                class="
                  border-0
                  px-3
                  py-1
                  placeholder-placeholder_color_1-300
                  text-text_color_1-600
                  bg-bg_color_white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none
                  focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {

      },
    };
  },
  emits: ['onApply'],
  methods: {
    search() {
      this.$emit("onApply", this.form);
    }
  }
};
</script>
