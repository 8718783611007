import { UserRepo } from "@/repos";
import { pageSize } from '@/configs/api.config'

const perPage = pageSize || 10;

class UserService {
    async list(params) {
        params = params || {};
        params['page_size'] = params['page_size'] || perPage;

        let { data } = await UserRepo.list(params);
        data.page = params.page || 1;
        data.totalPage = Math.ceil(data.count / params['page_size']);

        return data;
    }

    async get(id) {
        let { data } = await UserRepo.get(id);
        return data;
    }

    async create(user) {
        let { data } = await UserRepo.create(user);
        return data;
    }
    
    async update(id, user) {
        let { data } = await UserRepo.update(id, user);
        return data;
    }

    async delete(id) {
        let { data } = await UserRepo.delete(id);
        return data;
    }

    async import(file) {
        let { data } = await UserRepo.import(file);
        return data;
    }

    async setEvents(id, events) {
        let { data } = await UserRepo.setEvents(id, events);
        return data;
    }

    async setSegments(id, segments) {
        let { data } = await UserRepo.setSegments(id, segments);
        return data;
    }

    async addAccPurchase(id, form) {
        let { data } = await UserRepo.addAccPurchase(id, form);
        return data;
    }
}

export default new UserService();