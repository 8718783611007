<template>
  <div class="container mx-auto px-4 h-full">
    <loader
      :model="loading"
      :detail="error"
      @close="
        loading = null;
        error = null;
      "
    />
    <div class="flex content-center items-center justify-center w-full">
      <div class="py-20 text-3xl">{{ $t("app.name") }}</div>
    </div>
    <div class="flex content-center items-center justify-center w-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="
            relative
            flex flex-col
            min-w-0
            break-words
            w-full
            mb-6
            shadow-lg
            rounded-lg
            bg-bg_color_1-200
            border-0
          "
        >
          <div class="flex-auto px-4 lg:px-10 py-10">
            <form @submit.prevent="login">
              <div class="relative w-full mb-3">
                <label
                  class="
                    block
                    uppercase
                    text-text_color_1-600
                    text-xs
                    font-bold
                    mb-2
                  "
                  htmlFor="grid-password"
                >
                  {{ $t("login.username") }}
                </label>
                <input
                  type="text"
                  v-model="username"
                  class="
                    border-0
                    px-3
                    py-3
                    placeholder-placeholder_color_1-300
                    text-text_color_1-600
                    bg-bg_color_white
                    rounded
                    text-sm
                    shadow
                    focus:outline-none
                    focus:ring
                    w-full
                    ease-linear
                    transition-all
                    duration-150
                  "
                  :placeholder="$t('login.username_placeholder')"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="
                    block
                    uppercase
                    text-text_color_1-600
                    text-xs
                    font-bold
                    mb-2
                  "
                  htmlFor="grid-password"
                >
                  {{ $t("login.password") }}
                </label>
                <input
                  type="password"
                  v-model="password"
                  class="
                    border-0
                    px-3
                    py-3
                    placeholder-placeholder_color_1-300
                    text-text_color_1-600
                    bg-bg_color_white
                    rounded
                    text-sm
                    shadow
                    focus:outline-none
                    focus:ring
                    w-full
                    ease-linear
                    transition-all
                    duration-150
                  "
                  :placeholder="$t('login.password_placeholder')"
                />
              </div>
              <!-- <div>
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    id="customCheckLogin"
                    v-model="rememberme"
                    type="checkbox"
                    class="form-checkbox border-0 rounded text-text_color_1-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                  />
                  <span
                    class="ml-2 text-sm font-semibold text-text_color_1-600"
                  >
                     {{ $t('login.remember_me') }}
                  </span>
                </label>
              </div> -->

              <div class="text-center mt-6">
                <button
                  class="
                    bg-bg_color_1-800
                    text-text_color_white
                    active:bg-bg_color_1-600
                    text-sm
                    font-bold
                    uppercase
                    px-6
                    py-3
                    rounded
                    shadow
                    hover:shadow-lg
                    outline-none
                    focus:outline-none
                    mr-1
                    mb-1
                    w-full
                    ease-linear
                    transition-all
                    duration-150
                  "
                  type="submit"
                >
                  {{ $t("login.signin") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AuthService } from "@/services";

export default {
  data() {
    return {
      username: "",
      password: "",
      rememberme: false,
      loading: null,
      error: null,
    };
  },
  mounted() {
    localStorage.removeItem("access_token");
  },
  methods: {
    async login() {
      this.loading = "load";
      try {
        const resp = await AuthService.login(this.username, this.password);
        if (resp.token) {
          localStorage.setItem("access_token", resp.token);
          this.$router.push("/admin/users");
        } else {
          localStorage.removeItem("access_token");
          this.loading = "fail";
          this.error = "Wrong username or password";
        }
        this.loading = "success";
      } catch (e) {
        localStorage.removeItem("access_token");
        this.loading = "fail";
        this.error = "Wrong username or password";
      }
    },
  },
};
</script>
