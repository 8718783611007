<template>
  <div class="flex flex-wrap">
    <div
      class="
        relative
        flex flex-col
        min-w-0
        break-words
        w-full
        mb-6
        shadow-lg
        rounded-lg
        bg-bg_color_1-100
        border-0
      "
    >
      <div class="rounded bg-bg_color_white mb-0 px-6 py-3">
        <div class="text-center flex justify-between">
          <h3 class="text-text_color_1-700 text-2xl font-bold">
            <span class="hidden md:inline">{{ $t("app.name") }} <i class="fas fa-long-arrow-alt-right" /></span>
            {{ $t("sidebar.menu.line") }}
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
