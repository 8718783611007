<template>
  <div class="container px-4 min-h-screen">
    <loader
      :model="loading"
      :detail="error"
      @close="
        loading = null;
        error = null;
      "
    />
    <Head />
    <Detail
      :model="selectedData"
      @edit="showForm"
      @delete="deleteUser"
      @close="closeDetail"
    />
    <Form
      :active="isShowForm"
      :model="editingData"
      :provinces="provinceList"
      :districts="districtList"
      :subDistricts="subDistrictList"
      @close="closeForm"
      @onSubmitUpdate="updateUser"
      @onSubmitCreate="createUser"
    />
    <Import
      :active="isShowImport"
      @close="closeImport"
      @onSubmitImport="importUsers"
    />
    <Segment
      :active="isShowSegment"
      @close="closeSegment"
      :records="segments"
      :model="currentSegments"
      @onSubmit="updateSegments"
    />
    <Event
      :active="isShowEvent"
      @close="closeEvent"
      :records="packages"
      :model="currentEvent"
      @onSubmit="updateEvents"
    />
    <Line :active="isShowLine" :coupons="coupons" @close="closeLine" @onSubmit="sendLine" />
    <AccPurchase
      :active="isShowAccPurchase"
      @close="closeAccPurchase"
      @onSubmit="addAccPurchase"
    />
    <div class="flex flex-wrap">
      <Search
        @onApply="applySearch"
        :segments="segments"
        :events="events"
        :selectedSegment="filters.usercustomgroup__custom_group__id"
        :selectedEvent="filters.usereventpackage__event_package__id"
      />
    </div>
    <div class="flex flex-wrap">
      <Table
        name="Users"
        :records="records"
        :page="page"
        :totalPage="totalPage"
        @onRecClick="showDetail"
        @onAddClick="() => showForm(null)"
        @onImportClick="showImport"
        @onEventClick="showEvent"
        @onSegmentClick="showSegment"
        @onAccPurchaseClick="showAccPurchase"
        @onLineClick="showLine"
        @onPageChange="
          (toPage) => {
            page = toPage;
            fetchUser();
          }
        "
        @onSortChange="
          (query) => {
            ordering = query;
            fetchUser();
          }
        "
      />
    </div>
  </div>
</template>
<script>
import Head from "@/components/Users/Head.vue";
import Table from "@/components/Users/Table.vue";
import Search from "@/components/Users/Search.vue";
import Detail from "@/components/Users/Detail.vue";
import Form from "@/components/Users/Form.vue";
import Import from "@/components/Users/Import.vue";
import Segment from "@/components/Users/Segment.vue";
import Event from "@/components/Users/Event.vue";
import AccPurchase from "@/components/Users/AccPurchase.vue";
import Line from "@/components/Users/Line.vue";

import provinces from "@/assets/address/provinces.json";
import districts from "@/assets/address/districts.json";
import subDistricts from "@/assets/address/sub_districts.json";

import {
  UserService,
  SegmentService,
  EventService,
  NotificationService,
  CouponService
} from "@/services";

export default {
  name: "user-page",
  data() {
    return {
      selectedData: null,
      editingData: null,
      isShowForm: false,
      isShowImport: false,
      isShowResetPassword: false,
      isShowSegment: false,
      isShowAccPurchase: false,
      isShowLine: false,
      segments: [],
      currentSegments: [],
      isShowEvent: false,
      events: [],
      packages: [],
      coupons: [],
      currentEvent: null,
      records: [],
      page: 1,
      totalPage: 1,
      search: null,
      filters: {
        usercustomgroup__custom_group__id: null,
        usereventpackage__event_package__id: null,
      },
      ordering: null,
      loading: null,
      error: null,
      provinces,
      districts,
      subDistricts,
    };
  },
  components: {
    Head,
    Table,
    Search,
    Detail,
    Form,
    Import,
    Segment,
    Event,
    AccPurchase,
    Line,
  },
  async mounted() {
    this.loading = "load";
    this.filters.usercustomgroup__custom_group__id =
      this.$route.query.segment || null;
    this.filters.usereventpackage__event_package__id =
      this.$route.query.event || null;
    try {
      await this.fetchUser();
      await this.fetchSegment();
      await this.fetchEvent();
      await this.fetchCoupon();
      this.loading = "success";
    } catch (e) {
      this.loading = "fail";
      this.error = e.message;
    }
  },
  methods: {
    async fetchUser() {
      this.loading = "load";
      try {
        const data = await UserService.list({
          page: this.page,
          search: this.search,
          ordering: this.ordering,
          ...this.filters,
        });
        this.records = data.results;
        this.page = data.page;
        this.totalPage = data.totalPage;
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    async fetchSegment() {
      const data = await SegmentService.list({
        page_size: 9999,
      });
      this.segments = data.results;
    },
    async fetchEvent() {
      const data = await EventService.list({
        page_size: 9999,
      });
      this.events = data.results;
      this.packages = data.results.filter((e) => e.type === "package");
    },
    async fetchCoupon() {
      const data = await CouponService.list({
        page_size: 9999,
      });
      this.coupons = data.results.filter(c => c.collect_method === 'exclusive');
    },
    applySearch(form) {
      this.loading = "load";
      try {
        this.search = form.keyword || null;
        this.filters.usercustomgroup__custom_group__id =
          form.custom_group || null;
        this.filters.usereventpackage__event_package__id =
          form.event_package || null;
        this.page = 1;
        this.fetchUser();
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    showDetail(user) {
      this.closeForm();
      this.closeImport();
      this.closeSegment();
      this.closeEvent();
      this.closeAccPurchase();
      this.closeLine();

      let isSwitch = this.selectedData && user != this.selectedData;
      if (isSwitch) {
        this.closeDetail();
        setTimeout(() => {
          this.selectedData = user;
        }, 300);
      } else if (user == this.selectedData) {
        this.closeDetail();
      } else {
        this.selectedData = user;
      }
    },
    closeDetail() {
      this.selectedData = null;
    },
    showForm(user) {
      this.closeDetail();
      this.closeImport();
      this.closeSegment();
      this.closeEvent();
      this.closeAccPurchase();
      this.closeLine();

      if (this.isShowForm) {
        this.closeForm();
        setTimeout(() => {
          this.isShowForm = true;
          this.editingData = user;
        }, 300);
      } else {
        this.isShowForm = true;
        this.editingData = user;
      }
    },
    closeForm() {
      this.isShowForm = false;
      this.editingData = null;
    },
    async deleteUser(data) {
      let confirmed = window.confirm(
        `${this.$t("app.confirm-delete")} \n(${this.$t("sidebar.menu.user")} ${
          data.first_name
        })`
      );
      if (confirmed) {
        this.loading = "load";
        try {
          await UserService.delete(data.id);
          this.fetchUser();
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }

      this.closeDetail();
    },
    async updateUser(data) {
      let confirmed = window.confirm(
        `${this.$t("app.confirm-update")} \n(${this.$t("sidebar.menu.user")} ${
          data.first_name
        })`
      );
      if (confirmed) {
        this.loading = "load";
        try {
          await UserService.update(data.id, data);
          this.fetchUser();
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }
      this.closeForm();
    },
    async createUser(data) {
      this.loading = "load";
      try {
        await UserService.create(data);
        this.fetchUser();
        this.closeForm();
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    showImport() {
      this.closeDetail();
      this.closeForm();
      this.closeSegment();
      this.closeEvent();
      this.closeAccPurchase();
      this.closeLine();

      this.isShowImport = true;
    },
    async importUsers(file) {
      let confirmed = window.confirm(`${this.$t("app.confirm-import")}`);
      if (confirmed) {
        this.loading = "load";
        try {
          await UserService.import(file);
          this.fetchUser();
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }
      this.closeImport();
    },
    closeImport() {
      this.isShowImport = false;
    },
    showSegment(user) {
      this.closeForm();
      this.closeImport();
      this.closeDetail();
      this.closeEvent();
      this.closeAccPurchase();
      this.closeLine();

      if (this.isShowSegment) {
        this.closeSegment();
        setTimeout(() => {
          this.isShowSegment = true;
          this.editingData = user;
          if (user) {
            this.currentSegments = user.user_custom_group.reduce((map, obj) => {
              map[obj.custom_group.id] = true;
              return map;
            }, {});
          }
        }, 300);
      } else {
        this.isShowSegment = true;
        this.editingData = user;
        if (user) {
          this.currentSegments = user.user_custom_group.reduce((map, obj) => {
            map[obj.custom_group.id] = true;
            return map;
          }, {});
        }
      }
    },
    closeSegment() {
      this.isShowSegment = false;
      this.currentSegments = [];
    },
    async updateSegments(segmentIds) {
      this.loading = "load";
      try {
        await UserService.setSegments(this.editingData.id, segmentIds);
        this.closeSegment();
        await this.fetchUser();
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    showEvent(user) {
      this.closeForm();
      this.closeImport();
      this.closeDetail();
      this.closeSegment();
      this.closeAccPurchase();
      this.closeLine();

      if (this.isShowEvent) {
        this.closeEvent();
        setTimeout(() => {
          this.isShowEvent = true;
          this.editingData = user;
          if (user) {
            this.currentEvent = user.user_package.event_package.id;
          }
        }, 300);
      } else {
        this.isShowEvent = true;
        this.editingData = user;
        if (user) {
          this.currentEvent = user.user_package.event_package.id;
        }
      }
    },
    closeEvent() {
      this.isShowEvent = false;
      this.currentEvent = null;
    },
    async updateEvents(eventIds) {
      this.loading = "load";
      try {
        await UserService.setEvents(this.editingData.id, eventIds);
        this.closeEvent();
        await this.fetchUser();
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
    },
    showAccPurchase(user) {
      this.closeDetail();
      this.closeImport();
      this.closeSegment();
      this.closeEvent();
      this.closeForm();
      this.closeLine();

      if (this.isShowAccPurchase) {
        this.closeAccPurchase();
        setTimeout(() => {
          this.isShowAccPurchase = true;
          this.editingData = user;
        }, 300);
      } else {
        this.isShowAccPurchase = true;
        this.editingData = user;
      }
    },
    closeAccPurchase() {
      this.isShowAccPurchase = false;
      this.editingData = null;
    },
    async addAccPurchase(data) {
      let confirmed = window.confirm(`${this.$t("app.confirm-import")}`);
      if (confirmed) {
        this.loading = "load";
        try {
          await UserService.addAccPurchase(this.editingData.id, data);
          this.fetchUser();
          this.loading = "success";
        } catch (e) {
          this.loading = "fail";
          this.error = e.message;
        }
      }
      this.closeAccPurchase();
    },
    showLine(user) {
      this.closeDetail();
      this.closeImport();
      this.closeSegment();
      this.closeEvent();
      this.closeForm();
      this.closeAccPurchase();

      if (this.isShowLine) {
        this.closeLine();
        setTimeout(() => {
          this.isShowLine = true;
          this.editingData = user;
        }, 300);
      } else {
        this.isShowLine = true;
        this.editingData = user;
      }
    },
    closeLine() {
      this.isShowLine = false;
      this.editingData = null;
    },
    async sendLine(data) {
      this.loading = "load";
      try {
        await NotificationService.createSingle({
          ...data,
          line_uuid: this.editingData.line_user_id,
          mobile: this.editingData.mobile,
        });
        this.loading = "success";
      } catch (e) {
        this.loading = "fail";
        this.error = e.message;
      }
      this.closeLine();
    },
  },
  computed: {
    provinceList: function () {
      return Object.keys(this.provinces).map((id) => {
        return {
          id,
          ...this.provinces[id],
        };
      });
    },
    districtList: function () {
      return Object.keys(this.districts).map((id) => {
        return {
          id,
          ...this.districts[id],
        };
      });
    },
    subDistrictList: function () {
      return Object.keys(this.subDistricts).map((id) => {
        return {
          id,
          ...this.subDistricts[id],
        };
      });
    },
  },
};
</script>
